import React from "react";

const Loader = () => {
    return (
        <div className="loader">
            <div className="bar"/>
        </div>
    )
};


export default Loader;