import React from "react";
import "./css/CenterListEmptySearch.css";

const CenterListEmptySearch = () => {
    return (
        <div className="empty-advanced-search">
            <div className="clubs-not-found">За критеріями пошуку центрів не знайдено</div>
        </div>
    );
};

export default CenterListEmptySearch;