module.exports = {
  marathonDay: [
    {
      id: "1",
      pathUrl: "923209",
      name: `Крок 1. Оточіть себе українською мовою`,
      subheader: ``,
      text: `Ми розпочали наш Мовомаратон до 30-річчя Незалежності України! Дякуємо усім, хто приєднався! Упродовж 30 днів ми зробимо 30 кроків  до української мови.
      
      Що чекає на учасників? Щодня ми надсилатимемо вам у Telegram-чат невеликі навчальні матеріали та завдання, які допоможуть подолати мовний бар’єр і зробити так, щоб української мови навколо  стало більше. Упродовж  30 днів ми пройдемо шлях від розуміння мотивації, отримання психологічної підтримки для подолання мовного бар’єру – до практики і занурення в українську мову та культуру.`,
      text2: `Ви зможете поставити запитання спеціалістам під час вебінарів (про час ми повідомимо додатково, вебінари будуть доступні у запису):

      ●\t20 серпня о 10.00 – вебінар «Перейти на українську: виклики та можливості». Ольга Андрусенко, мовна тренерка, викладачка-волонтерка, співавторка програм курсів української мови.
      ●\t30 серпня – вебінар «Голос і мовлення». Олександр Жуган, викладач акторської майстерності, психолог.
      ●\t2 вересня – вебінар «Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка». Мар’яна Лелик, тренерка із мнемотехніки, письменниця,.
      ●\t11 вересня – вебінар «Як розмовляти з дітьми про мистецтво». Оксана Садовенко, викладачка мистецтва для дітей.
    
     Крім того, ми плануємо кілька онлайн-розмов про українську культуру, консультацію з викладачкою української мови, бесіду з психологом, який надасть підтримку та відповість на запитання, що стосуватимуться психологічного бар’єру та мовних ситуацій. 
    
     Ми розпочинаємо Мовомаратон із першого кроку: оточіть себе українською мовою. 
    
     Почнімо із ґаджетів.
    
     Алгоритми багатьох сайтів і соціальних мереж працюють таким чином, що показують насамперед сторінки й відео тією мовою, яка вказана в налаштуваннях вашої операційної системи і браузера. Для того, щоб  мережа видавала більше результатів українською, потрібно додати українську мову у налаштуваннях свого комп’ютера і браузера.
`,
      taskTitle: `Завдання`,
      task: `Оточіть себе українською мовою. Розпочніть із ґаджетів. Знайдіть у налаштуваннях телефона та комп’ютера українську мову. Відкрийте ваш браузер, в якому шукаєте інформацію та переглядаєте сайти. Налаштування браузера знаходяться зазвичай у правому верхньому кутку екрана. Там можна обрати мову – оберіть українську. Коли шукаєте інформацію в ґуґлі чи ютубі, пишіть українською.`,
    },
    {
      id: "2",
      pathUrl: "042586",
      name: `Крок 2. Подбайте про свою мотивацію`,
      subheader: ``,
      text: `Доброго ранку! Нині будемо говорити про мотивацію. Що вас мотивує рухатись уперед? Ваші цінності, бажання, винагорода чи тиск зовні? Знайдіть свою внутрішню мотивацію, яка допоможе вам говорити українською.
      
      Є речі, які багатьом заважають: сором за свою, начебто, не таку українську (яка насправді цілковито прийнятна), помилки, яких, начебто, не можна робити (але це не так), тиск оточення (однак у вас в оточенні знайдуться люди, які вас підтримають). 
      `,
      text2: `Перегляньте вебінар «Перейти на українську: виклики і можливості». Мовна тренерка, викладачка-волонтерка, методистка та програмна менеджерка освітньої платформи «Є-мова» Ольга Андрусенко розповідає про мотивацію і про те, що відбувається з нами під час переходу на українську мову.  \n Вебінар: <p><a href= "https://youtu.be/Chvx7nQtIbE ">https://youtu.be/Chvx7nQtIbE </a></p>
      `,
      taskTitle: `Завдання`,
      task: `Напишіть мотиваційний лист самому собі (можна електронний, але краще паперовий). Що 
      вас мотивує говорити українською? Хто може вас підтримати? Хто у вашому середовищі 
      вже говорить українською і може вам допомогти практикувати? Якою чи яким ви хочете 
      бути після 30 днів Мовомаратону? Збережіть цей лист, ми повернемося до нього наприкінці
      Мовомаратону.`,
    },
    {
      id: "3",
      pathUrl: "338874",
      name: `Крок 3. Знайомтеся українською`,
      subheader: ``,
      text: `Ви вже обміркували свою мотивацію і готові говорити українською, тому лише почніть! 

        Починайте говорити з людьми українською з першого дня знайомства. Таким чином вам 
        буде легше спілкуватися з цією людиною українською і надалі.
        Розкажіть друзям, що ви берете участь у Мовомаратоні,  попросіть про підтримку.

`,
      text2: `Ми розуміємо, що іноді психологічно незручно переходити на українську зі знайомими, бо хочеться уникнути запитань на тему мови. Порада від Олександра Жугана, викладача акторської майстерності, який перейшов на українську п’ять років тому під час подібного марафону до Дня Незалежності:

      «Коли мене запитували, що сталося і чому почав говорити українською, я казав своїм друзям і учням, що беру участь у Мовомарафоні до річниці Незалежності. Питань більше не виникало».

 Олександр Медведєв, викладач комп’ютерної графіки та дизайну, який взяв участь у першому челенджі «Навчай українською», поділився своїм досвідом:«Часто перепоною стає сором’язливість: адже ось учора ми з учнями спілкувалися російською, а сьогодні я раптом став україномовним. Як вони це сприймуть? Чи не піднімуть на кпини? Чи не захочуть піти від мене через незручність спілкування? Як це сприймуть батьки учнів, колеги на роботі? Не треба вдавати, що нічого не сталося. Варто чесно сказати всім: відтепер я розмовляю українською, це моя позиція. Обґрунтована вас триматися свого слова, не дасть відступитися; 2) ваша відповідь або лекція українською мовою не стане несподіванкою для учнів, інших людей; 3) ви змусите якийсь відсоток учнів перейти на українську мову, принаймні з вами; 4) ви дасте поштовх іншим людям навколо — учням, колегам, батькам учнів — замислитися над власною мовою спілкування. А можливо, виявиться,  що ви не один такий. Що люди навколо теж давно прагнули спілкуватися нашою мовою, але їм бракувало поштовху, так само, як ще вчора вам!».
`,
      taskTitle: `Завдання`,
      task: `Потренуйтесь розповідати про себе українською мовою. Станьте перед дзеркалом і вголос 
      представтеся. Назвіть своє ім’я, розкажіть, звідки ви, чим займаєтеся, що любите робити, 
      які у вас досягнення. Виходить?

      Якщо ви не знаєте, як перекласти якесь слово з російської на українську, скористайтеся 
      онлайн словником <a href= "https://r2u.org.ua/ ">https://r2u.org.ua/ </a> – це один із найкращих ресурсів. 
`,
    },
    {
      id: "4",
      pathUrl: "357275",
      name: `Крок 4. Дивіться український Youtube`,
      subheader: ``,
      text: `Сьогодні відпочиньте в українському культурному полі та познайомтеся з українським ютубом. Ми підшукали канали, які відкриють вам світ науки, музики, кіно, подорожей та всякого цікавого.

        Канали, які розповідають про науку популярно: 
`,
      text2: `<span id ="headline">Клятий Раціоналіст</span>  <a  href= "https://bit.ly/3lHCINS ">https://bit.ly/3lHCINS </a>
      Науково-популярний контент, що пропагує раціоналізм. Презумпція наукового методу. 

      <span id ="headline">Цікава наука</span> <a  href= "https://bit.ly/3ptUDu0">https://bit.ly/3ptUDu0 </a> 
      Тут ви знайдете перекладені українською науково-популярні та освітні ролики на різні наукові теми з фізики, астрономії, біології, географії та математики. 

      <span id ="headline">Tokar.ua</span>  <a  href= "http://bit.ly/37fmBjx">http://bit.ly/37fmBjx</a> 
     Простими словами про складне - безпеку, технології та винаходи. Цікаво і доступно.

     <span id ="headline">Довколаботаніка</span> <a href= "https://bit.ly/2UrIhYI">https://bit.ly/2UrIhYI</a> 
      Несподівані факти зі світу ботаніки, які вас вразять. 

     <span id ="headline">Гуманітарні науки:</span>

     <span id ="headline">Твоя підпільна гуманітарка</span> <a  href= "https://bit.ly/3lxb9qv">https://bit.ly/3lxb9qv</a> 
    Лекції, інтерв’ю та онлайн-дискусії, що так чи інакше  стосуються гуманітарних наук. Наша улюблена рубрика – Український бестіарій. Повірте, ви про бабая, відьму і чорта стільки не знали.

     <span id ="headline">Імені Т.Г. Шевченка</span> <a  href= "https://bit.ly/2UnsINW">https://bit.ly/2UnsINW</a> 
    Тут ви знайдете відео від братів Капранових, де вони розказують популярно про історію України.

     <span id ="headline">Історія без міфів</span> <a href= "https://bit.ly/35yXEBr">https://bit.ly/35yXEBr</a> 
    Світова історія та історія України без прикрас та фальсифікацій. 

     <span id ="headline">Wild Fox</span> <a  href= "http://bit.ly/2Gd18M9">http://bit.ly/2Gd18M9</a>
    Це популяризація знань з історії та культури України. Знатимете, що готували в часи Київської Русі і яким бізнесом займалися.

    <span id ="headline">Для дітей:</span>

    <span id ="headline">Майстерня дитячого розвитку</span> <a  href= "https://bit.ly/3kvg2zc">https://bit.ly/3kvg2zc</a> 
    Канал про психологію, розвиток та батьківство. Тут можна знайти  ігри для розвитку та різноманітні вправи, які згодяться у роботі з дітьми.

    <span id ="headline">Книга-мандрівка. Україна</span> <a  href= "http://bit.ly/2unA4qY">http://bit.ly/2unA4qY</a> 
    Мультсеріал, заснований на реальних подіях, описаних в ілюстрованому виданні «Книга-мандрівка. Україна».

    <span id ="headline">Про подорожі:</span>

    <span id ="headline">Ukraїner </span><a  href= "http://bit.ly/2sIsIOf">http://bit.ly/2sIsIOf</a> 
    Експедиція Україною, подорожі, активний туризм, відкриття, мандри, антропологія та етнографія. Неймовірної краси відео з висоти пташиного польоту!

    <span id ="headline">ПроПоходи</span> <a  href= "http://bit.ly/2tJxknU">http://bit.ly/2tJxknU</a> 
    Про мандрівки в гори з сучасним спорядженням та враження від останніх досягнень у світі полегшеного туристичного екіпірування.

    <span id ="headline">Двоколісні хроніки</span>  <a  href= "https://bit.ly/2IzvNrX">https://bit.ly/2IzvNrX</a> 
    Музиканти гурту «Фолькнери» Володимир Муляр, Ярина Квітка та їхній малюк Марко, який народився у мандрівці, їздять  світом на велосипедах і показують світ таким, яким вони його бачать. Кілька років тому вони доїхали на велосипедах з України до Австралії.

    <span id ="headline">Йога:</span>

    <span id ="headline">Yoga Dopomoga</span> <a  href= "https://bit.ly/35vIFry ">https://r2u.org.ua/ </a>
    Все про йогу українською мовою.

    <span id ="headline">Про кіно:</span>   

    <span id ="headline">Загін Кіноманів</span> <a  href= "https://bit.ly/2IDl6EF">https://bit.ly/2IDl6EF </a>
    Тут рецензії на українські та іноземні фільми, а також цікаві репортажі з фестивалів і прем'єр.

    <span id ="headline">Geek Journal</span> <a  href= "https://www.youtube.com/c/GeekJournal" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://www.youtube.com/c/GeekJournal</a> 
    Тут можна знайти огляди українських та іноземних фільмів, актуальні новини зі всього світу та ще багато цікавого. 

    <span id ="headline">Корейці на човні</span> <a  href= "http://bit.ly/37iQKha">http://bit.ly/37iQKha</a> 
    Корейці знімають відео про Україну і говорять українською!

    <span id ="headline">Про музику:</span>

     <span id ="headline">З любов’ю до дітей - Дитячі пісні</span> <a  href= "https://www.youtube.com/user/SonechkoProject" style="-webkit-box-orient: vertical;-webkit-line-clamp: 1; overflow: hidden;display: -webkit-box; width: 60%;">https://www.youtube.com/user/SonechkoProject</a>
    Тут ви знайдете веселі дитячі пісні, цікаві абетки, руханки, розвивальні відео та мультики для дітей українською мовою. 
    
    <span id ="headline"> Polyphonyproject.com</span>  <a  href= "http://bit.ly/36fIiP1">http://bit.ly/36fIiP1</a> 
     Профейсійні багатоголосі записи українських народних автентичних пісень.

Найповніший список ютуб-каналів українською мовою є тут: <a  href= "https://manifest.in.ua/rt/">https://manifest.in.ua/rt/</a>
`,
      taskTitle: `Завдання`,
      task: `Подивіться три відео на ютубі українською на різну тематику. Зверніть увагу, які відео вам почав пропонувати ютуб після цього. Якщо бажаєте, поділіться у соцмережах українськими ютуб-каналами, які вам сподобалися. Не забудьте поставити у дописах хештеги #Мовомаратон і #МоваНезалежності, щоб ми могли прочитати ваш допис.`,
    },
    {
      id: "5",
      pathUrl: "059513",
      name: `Крок 5. Готуємося відзначити 30 років Незалежності!`,
      subheader: ``,
      text: `Завтра ми усі будемо святкувати 30-річчя Незалежності України, тож пропонуємо зробити ці два дні нашого Мовомаратону – сьогодні й завтра – особливими. Виділіть 20 хвилин вашого часу і спробуйте пригадати, що визначного й натхненного для вас відбулося за останні 30 років в історії України. `,
      text2: ``,
      taskTitle: `Завдання`,
      task: `Складіть українською мовою список подій і речей, які надихали вас упродовж цих 30-ти років Незалежності. Улюблені пісні, книги, фестивалі українською, знакові події в історії України з 1991 року. Усі охочі можуть зробити про це дописи, вказавши хештеги #Мовомаратон і #МоваНезалежності, й поширити їх 23 чи 24 серпня на своїх сторінках у соцмережах.`,
    },
    {
      id: "6",
      pathUrl: "990234",
      name: `Крок 6. День Незалежності`,
      subheader: ``,
      text: `Вітаємо з Днем Незалежності! 
Згадаймо ці 30 років – як змінилася наша країна, як змінилися ми. Ми стали вільними, ми маємо однодумців. 
Пропонуємо потренувати сприйняття української на слух і прослухати лекцію відомої письменниці Оксани Забужко, яку вона прочитала під час цьогорічного Книжкового Арсеналу, про оптимізм української історії: <a  href= "https://youtu.be/NjXeWOW4J6k" style="color:#58a6ff" >https://youtu.be/NjXeWOW4J6k </a> 
Якщо ви вчора не встигли опублікувати ваші історії про події та речі, які вас надихали впродовж  цих 30-ти років Незалежності, то зробіть це сьогодні, вказавши хештеги #Мовомаратон і #МоваНезалежності.
`,
      text2: ``,
      taskTitle: ``,
      task: ``,
    },
    {
      id: "7",
      pathUrl: "472818",
      name: `Крок 7. Не бійтеся робити помилки`,
      subheader: ``,
      text: `Помилятися – не страшно. Головне – пробувати спілкуватися. Щоб вивчити будь-яку мову, нею потрібно почати розмовляти. І робити помилки, особливо на початку, – це НОРМАЛЬНО. Людина не може одразу говорити ідеально якоюсь мовою. 

Коли ми вивчаємо англійську, то багато помиляємося. Коли хтось пробує говорити російською, то спочатку говорить з помилками та українізмами. Однак існує стереотип, що українською треба говорити ідеально. Чому так?
`,
      text2: `Ми підготували для вас два корисні посилання на відео про те, звідки взявся цей стереотип.
 
Про суржик. Лекція письменниці та дослідниці Євгенії Кузнєцової  «Мова-меч №4: сором за діалекти», у якій ідеться  про те, як у СРСР ставилися до регіональних говірок, та чому люди почали їх соромитися: <a  href= "https://youtu.be/KbfBX-rGSqs">https://youtu.be/KbfBX-rGSqs</a> (триває 8 хв)

Дуже часто в категорію  «неправильної» мови потрапляють навіть питомо українські слова, які  просто схожі на російські. Лекція письменника і дослідника Остапа Українця про суржик: <a  href= "https://youtu.be/AiiWg9_XRTo">https://youtu.be/AiiWg9_XRTo</a> (триває майже 18 хв)

Ми нагадуємо про словник <a href= "https://r2u.org.ua/">https://r2u.org.ua/</a> –  один із найкращих ресурсів, на якому ви можете знайти переклад слів і приклади використання у реченні.
`,
      taskTitle: `Завдання`,
      task: `Дізнайтеся у родичів або відшукайте в інтернеті п’ять діалектних слів із вашої місцевості. Поділіться ними в чаті чи у соцмережах з хештегами #Мовомаратон і #МоваНезалежності.`,
    },
    {
      id: "8",
      pathUrl: "111784",
      name: `Крок 8. Познайомтеся із Українським правописом`,
      subheader: ``,
      text: `Вітаємо! Право́пис — це система загальноприйнятих правил, що визначають способи передачі мови на письмі. Олександр Пономарів, відомий український мовознавець, публіцист, професор КНУ ім. Т.Шевченка, два роки тому на запитання про те, які саме підстави слугували для змін правопису української мови, відповів: 

<span style="font-style: italic">«Підстави такі: у 1933 році комуністичний режим заборонив український правопис і замінив його зросійщеним. На жаль, не все пощастило повернути, але те, що повернули, варто підтримувати».</span> `,
        text2:`У 1918 році опубліковано проєкт офіційного українського правописного кодексу, запропонований професором Іваном Огієнком, у доопрацюванні якого згодом узяли участь академік Агатангел Кримський і професор Євген Тимченко. У 1919 році цей проєкт був виданий під назвою «Головніші правила українського правопису». Цього ж року спільне зібрання Української академії наук схвалило «Найголовніші правила українського правопису» — перший в історії України офіційний загальнодержавний правописний кодекс.  

У 1925 році уряд УСРР створив при Народному комісаріаті освіти Державну комісію для впорядкування українського правопису, а в 1927 році за наслідками роботи Всеукраїнської правописної конференції, що відбулася в Харкові, комісія підготувала проект всеукраїнського правопису, в опрацюванні якого взяли участь мовознавці з Наддніпрянщини й західноукраїнських земель. 6 вересня 1928 року цей Український правопис, який отримав неофіційну назву «харківський», затвердив нарком освіти УСРР Микола Скрипник. Та вже за кілька років після згортання політики «українізації» й самогубства Миколи Скрипника за вказівкою влади до «харківського» правопису внесли суттєві зміни, скасувавши низку його норм як націоналістичних і таких, що «покликані штучно відірвати українську мову від великої братньої російської».  

У 1933 році Народний комісаріат освіти УСРР схвалив інший правопис, який з незначними змінами й доповненнями (упродовж 1930-х рр., 1946 р., 1960 р.) діяв до 1989 року. З алфавіту було свавільно вилучено букву ґ, що вплинуло на функціонування графічної й фонетичної систем мови. Репресований «харківський» правопис з деякими змінами й доповненнями залишався чинним на західноукраїнських землях поза Українською РСР і в переважній частині української діаспори.  

У 1989 році затверджено і в 1990 році опубліковано нову редакцію Українського правопису, у якій поновлено букву ґ, уточнено й доповнено окремі правописні норми. У 2015–2018 роках Українська національна комісія з питань правопису, до якої увійшли фахівці мовознавчих установ Національної академії наук України та представники закладів вищої освіти з різних регіонів України, розробила проєкт нової редакції Українського правопису. Після громадського обговорення його схвалено на спільному засіданні Президії Національної академії наук України та Колегії Міністерства освіти і науки України 24 жовтня 2018 року. 

Про <span id="headline">вживання літери Ґ</span> ви можете прочитати у § 6. Українського правопису: 

Буква ґ передає на письмі задньоязиковий зімкнений приголосний: 

1. в українських та в давно запозичених і зукраїнізованих словах: а́ґрус, ґа́ва, ґа́зда́, ґандж, ґа́нок, ґату́нок, ґвалт, ґе́ґати, ґедзь, ґелґота́ти, ґелґотіти, ґерґелі, ґерґота́ти, ґерґоті́ти, ґи́ 

ґнути, ґирли́ґа, ґлей, ґніт (у лампі), ґо́ґельмо́ґель, ґонт(а), ґрасува́ти, ґра́ти (іменник), ґре́чний, ґринджо́ли, ґрунт, ґу́дзик, ґу́ля, ґура́льня, джиґу́н, дзи́ґа, дзи́ґлик, дриґа́ти і дри́ґати, ремиґа́ти тощо та в похідних від них: а́ґрусовий, ґаздува́ти, ґвалтува́ти, ґе́рґіт, ґратча́стий, ґрунтови́й, ґрунтува́ти(ся), ґу́дзиковий, ґу́лька, проґа́вити і под. 

2. у власних назвах — топонімах України: Ґорґа́ни (гірський масив), Ґоро́нда, У́ґля (села на Закарпатті), у прізвищах українців: Ґалаґа́н, Ґалято́вський, Ґе́ник, Ґерза́нич, Ґерда́н, Ґжи́цький, Ґи́ґа, Ґо́ґа, Ґо́йдич, Ґо́нта, Ґри́ґа, Ґудзь, Ґу́ла, Лома́ґа. 

 <span id="headline">Гете чи Ґете?</span> Про те, як позначаються звуки [g], [h] у словах іншомовного походження, ви можете прочитати у §122: <a href="https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;" >https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf</a>    

Варто пам'ятати про кличний відмінок. Тут ми знову зацитуємо професора Пономарева: 

«Українська мова належить до тих слов’янських мов, що зберегли кличний відмінок, тоді як інші (російська, білоруська тощо) втратили його. Тож у нашій мові тепер, як і колись, сім відмінків: називний, родовий, давальний, знахідний, орудний, місцевий та кличний. Кличний відмінок у нас (не так, як у чехів і поляків!) тривалий час перебував у становищі Попелюшки: його називали кличною формою, дозволяли при звертанні замість нього вживати іменники у формі відмінка називного. Витіснення кличного відмінка спричинило б зникнення ще однієї оригінальної, збереженої в віках риси». 

Тому частіше використовуйте кличний відмінок! 

Більше про кличний відмінок дивіться сторінку 95 Українського правопису <a href= "https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf</a>  

Нас запитують, чому Мовомаратон, а не Мовомарафон. Розповідаємо :) 

У розділі § 123 Українського правопису (2019) зазначено, що "буквосполучення th у словах грецького походження передаємо звичайно буквою т: антоло́гія, антрополо́гія, апте́ка, а́стма, бібліоте́ка, католи́цький, теа́тр, тео́рія, ортодо́кс, ортопе́дія, Амальте́я, Промете́й, Те́кля, Таї́сія, Теодо́р. У словах, узвичаєних в українській мові з ф, допускається орфографічна варіантність на зразок: ана́фема і ана́тема, дифіра́мб і дитира́мб, ефі́р і ете́р, ка́федра і кате́дра, логари́фм і логари́тм, міф, міфоло́гія і міт, мітоло́гія, Агата́нгел і Агафа́нгел, Афі́ни і Ате́ни, Борисфе́н і Бористе́н, Демосфе́н і Демосте́н, Ма́рфа і Ма́рта, Фесса́лія і Тесса́лія та ін". 

Тож правильно писати "марафон" і "маратон". Ми обрали написання "Мовомаратон", щоб показати учасникам, що українська мова багата і гнучка. І щоб ви не боялись зазирати у правопис і використовувати нові для себе варіанти.  `,
        taskTitle: `Завдання`,
      task: `Ознайомтеся із параграфами Українського правопису про літеру Ґ і про кличний відмінок. Потренуйтеся називати своїх близьких, друзів і родичів у кличному відмінку. Ось так, наприклад: Наталю, Віталію, Петре, Олеже або Олегу, Ірино, Софійко, синку, доню, колего тощо. Пригадайте кілька пісень, де вжито кличний відмінок. `,
    },
    {
      id: "9",
      pathUrl: "658688",
      name: `Крок 9. Ставте запитання `,
      subheader: "",
      text: `Не поспішайте позбуватися якогось слова зі свого мовлення, бо хтось зі знайомих вам сказав, що це росіянізм, калька абощо. Спершу загляньте у словник. Цілком може виявитися, що це питоме українське слово. Тому в цих суперечках варто щоразу ставити запитання і шукати відповіді у словниках і літературі. `,
        text2:`Ми сьогодні знову пригадаємо професора Пономарева, бо його блог на "BBC News Україна", де він відповідав на запитання читачів, був дуже помічний. Ми вибрали дещо, що може вам пригодитися.
        
        <span id="headline">Яка відмінність між потягом і поїздом? </span>

У цих слів є спільне значення – низка з'єднаних між собою залізничних вагонів. У такому значенні частіше вживане потяг. Воно має тривалу традицію в українській мові. У радянські часи його намагалися зробити застарілим. Редагували навіть класиків української літератури. Відомий вірш Володимира Сосюри "Коли потяг у даль загуркоче" друкували, замінивши слово потяг на поїзд. 

Але тепер усе стало на свої місця, і перша строфа цього вірша звучить так: 

Коли потяг у даль загуркоче, / Пригадаються знову мені / Дзвін гітари у місячні ночі, / Поцілунки й жоржини сумні. 

Поїзд у цьому значенні – рідше вживаний синонім. 

Але тільки поїзд використовують у значенні: ряд візків, саней з учасниками весільного обряду. Наприклад: "Молодий збирає поїзд і їде до молодої" (словник за редакцією Бориса Грінченка). 

<span id="headline">Як сказати “хороший слог”? </span>

Добрий стиль. 

Чи можна казати “куплю квартиру на правому березі Києва”, “він усе життя прожив на лівому березі Києва”, адже Київ — не річка і не має берегів? 

Потрібно казати: куплю квартиру в Києві на правому(чи лівому) березі Дніпра. 

<span id="headline">Як сказати українською “мне обидно, что ты не хочешь со мной разговаривать”? </span>

Українською мовою - “мені прикро, що ти не хочеш зі мною розмовляти”. 

<span id="headline">Як правильно: кращий чи найкращий фільм року? </span>

Коли йдеться про найвищий ступінь прикметників, потрібно казати найкращий: найкращий фільм року, конкурс на найкращий переклад, найкраща вистава театру.  

Це в російській мові лучший має значення найвищого ступеня порівняння, а в нас - ні. 

<span id="headline">Який рід мають назви кавових напоїв капучино, лате, ристрето, еспресо. Середній чи чоловічий, бо загальна назва - напій? </span>

Ці назви належать до середнього роду з огляду на їхню форму. Кава - так само напій, але за зовнішніми ознаками відносимо її до жіночого роду. `,
        taskTitle: `Завдання`,
      task: `Як правильно говорити українською таке: 
<dt>&emsp; 1.оточуюче середовище </dt>
<dt>&emsp; 2.керуючий відділом </dt>
<dt>&emsp; 3.краща книга року </dt>
<dt>&emsp; 4.ця сукня на тобі гарно дивиться </dt>
<dt>&emsp; 5.самий дорогий син </dt>`,
    },
    {
      id: "10",
      pathUrl: "064578",
      name: `Крок 10. Шукайте підтримку `,
      subheader: "",
      text: `Підтримка оточення - це дуже важливий компонент під час переходу на українську мову. 

Український студент, блогер Данило Гайдамаха (він же "Чорнобривий"), який зібрав у соцмережі TikTok аудиторію в понад 110 тис. осіб, розповів, як у 16 років перейшов на українську (<span style="font-style: italic">цитата із сайту Docudays UA</span>):`,
        text2:`"Для мене це було найважче. Я кілька разів пояснював своїй родині, що переходжу на українську, і власне говорив у родині рідною мовою, але мої слова просто не клеїлися. І це було дивовижно, тому що з друзями я абсолютно вільно говорив українською, а тут якийсь психологічний блок, і ти не можеш його переступити. І тоді я зрозумів: нехай все лишається, як є. Та згодом подумав, що це не лише стосується мого комфорту, але й впливає на мого молодшого брата. Тому що мовне питання формується у родині. Від, як ви вирішите мовне питання, якою мовою говоритимете, залежить, що ви залишите своїм дітям. Зрештою я попросив батьків спілкуватися так, як їм зручно, але підтримувати мого брата у прагненні говорити українською. У результаті батьки, які все життя говорили російською, себе теж пересилили — і перейшли на рідну мову. Уявіть собі: вихідці з Криму, які у понад 40-літньому віці змогли перейти на українську мову". 

Ініціатива “Навчай українською” радить заручитися підтримкою близьких і друзів. Ми також зібрали досвід викладачів, які перейшли на українську. Вони радять розповісти своїм учням і колегам, що ви берете участь у челенджі, і попросити підтримки. Діти вам допоможуть, коли ви під час заняття раптом забудете якесь слово. Колеги можуть допомогти шукати методичні матеріали українською.  

Ви можете переглянути один із вебінарів, які були записані для челенджу “Навчай українською” та освітньої платформи “Є-мова”:  

Вебінар про те, як дитячо-юнацький центр “Штурм” із Дніпра перейшов на українську мову навчання: <a href="https://youtu.be/ykRab96hK9A"> https://youtu.be/ykRab96hK9A</a> 

Вебінар викладача акторської майстерності Олександра Жугана "Як перейти на українську мову викладання" - про історію переходу на українську і труднощі з цим пов’язані, про досвід та помилки в українізації оточення, як і про що говорити з дітьми та їхніми батьками, коли піднімається питання мови: <a href="https://youtu.be/rIb2skc1tv4">https://youtu.be/rIb2skc1tv4</a>  

Лекція Олени Подопригори, заступниці директора з виховної роботи Бахмутського НВК № 11 Донецької області про перехід школи на українську мову викладання, заперечення батьків та правильну комунікацію: <a href="https://youtu.be/e2Xthfcwj98">https://youtu.be/e2Xthfcwj98</a>  `,
        taskTitle: `Завдання`,
      task: `Почніть завжди розмовляти українською із незнайомими людьми. Спілкуйтеся українською із касиром у супермаркеті, у банку, в громадському транспорті тощо.  `,
    },
    {
      id: "11",
      pathUrl: "023406",
      name: `Крок 11. Дивіться фільми українською `,
      subheader: "",
      text: `Сьогодні всі відпочивають, тож у нас буде приємна недільна тема - кіно. Кіно українською!  

Ви можете у пошуковику знайти декілька платформ, на яких можна легально дивитись фільми. Ми ж хочемо звернути вашу увагу на ось цей сайт: 
TAKFLIX — онлайн-кінотеатр, де можна легально дивитись українське кіно <a href="https://takflix.com/uk/films">https://takflix.com/uk/films</a>  `,
        text2:``,
        taskTitle: `Завдання`,
      task: `Поділіться списком ваших улюблених фільмів із озвученням українською. Було ж щось іще, крім “Альфа”, “Тачок” і “Лашкаво прошимо”? Було! ;) Якщо ви будете публікувати дописи, то не забудьте вказати хештеги #Мовомаратон і #МоваНезалежності `,
    },
    {
      id: "12",
      pathUrl: "421678",
      name: `Крок 12. Читайте українською вголос `,
      subheader: "",
      text: `Сьогодні ми підготували для вас вебінар “Голос і мовлення”, який проведе викладач акторської майстерності та сценічного мовлення Олександр Жуган. За допомогою скоромовок, текстів та імпровізаційних вправ для розвитку мовлення ви покращите свою українську.  

Трансляція розпочнеться 30 серпня о 12.00: <a href="https://youtu.be/keF34uPCHRk">https://youtu.be/keF34uPCHRk</a>  `,
        text2:``,
        taskTitle: `Завдання`,
      task: `Прочитайте вголос (спочатку повільно, а тоді швидко) уривок із твору Остапа Вишні “Ярмарок”: 
<p>
<p>“На ярмарок!.. 
<p>

І їдуть, і їдуть, і їдуть, і їдуть…Приїхали… <p>
Захряс майдан… <p>
Захряс гарбами, возами, бідами, кіньми, коровами, вівцями, волами, телятами, горшками, мисками, курми, вовною, лантухами, хмелем, смушками, матерією, чобітьми, цукерками, пряниками, квасом, пивом, руською гіркою, гребінками, косами, шкірами, ременем, чавунами, прядивом, хустками, полотном, дьогтем, гасом, дранками, сорочками, спідницями, килимами, щетиною, діжками, рогами, майками, воском, медом, мелясом, таранею, оселедцями, кочесами, ходами, склом, яйцями, запасками, плахтами, пирогами, салом, м’ясом, ковбасою, смаженою рибою, ряднами, скринями, гвіздками, молотками, свиньми, крамарями, циганами, баришниками, людьми, дітьми і сліпцями… 
І все це ворушиться, дихає, курить, говорить, кричить, лається, мукає, мекає, ірже, ігігікає, ремиґає, позіхає, кувікає, хреститься, божиться, матюкається, заприсягається, пахне, кудкудахтає, квокче, смалить одне одного по руках, грає на гармонію, на скрипку, причитує, п’є квас, їсть тараню, «булькає», лускає насіння і крутиться на каруселі…” 

<p>Знайдіть декілька скоромовок українською. Можете придумати свою скоромовку. Ми от придумали таку: кольорова королева коло рову ловить лева.  `,
    },
    {
      id: "13",
      pathUrl: "193478",
      name: `Крок 13. Сваріться і лайтеся українською `,
      subheader: "",
      text: `Ох, що зараз почнеться! Дехто з вас відчує обурення, що ми радимо лаятись українською. І ви цілковито маєте рацію. Однак трапляються ситуації, коли хочеться сказати міцне слівце, щоб кудись подіти вибух люті, подиву, страху чи розчарування. Врешті, лайка – це можливість скинути емоційне напруження. Ми не будемо радити, як справлятися з емоціями, а зібрали декілька порад, як лаятись українською. 

 `,
        text2:`Вчителька української мови та літератури в Новопечерській школі Анастасія Євдокимова розповідає (джерело: <a href="https://bit.ly/2UQq5Is">https://bit.ly/2UQq5Is</a>): 

<li>Сьогодні обсценну лексику складно назвати недрукованою, нецензурною чи нелітературною. Її можна знайти і у фольклорі, і в класичних текстах. Наприклад, «Енеїда» Івана Котляревського — енциклопедія народного життя у всіх сенсах. 
<li>Літературним джерелом народної мудрості та прокльонів на будь-який смак стане оповідання ХІХ століття про героїнь з народу Івана Нечуя-Левицького «Баба Параска і баба Палажка», які постійно сваряться і лаються.  
<li>У 2008 році вийшла книга, яка стане в пригоді авторам законопроєкту, коли потрібно буде розділити цензурні та нецензурні слова — «Українська мова без табу. Словник нецензурної лексики та її відповідників: Обсценізми, евфемізми, сексуалізми» Лесі Ставицької. 

Анастасія Євдокимова підготувала фановий словник добірної автентичної української лайки на всі випадки життя (публікація на сайті Освіторія). 

<li>Матері його ковінька (ковінька — палиця із загнутим кінцем). 
<li>Сто копанок чортів тобі в печінку. 
<li>А щоб твоєю мордою просо молотили. 
<li>А щоб ти щастя не знав. 
<li>А щоб тебе підняло і гепнуло. 
<li>А щоб тобі булька з носа вискочила. 
<li>А щоб тебе муха вбрикнула. 
<li>А щоб тебе колька сколола. 
<li>А щоб тобі курка на ногу наступила. 
<li>Най тебе качка копне! 
<li>Мати Василева! 
<li>Йосип голий! 
<li>Ах ти ж когут общипаний! 
<li>Кнур ефіопський! 
<li>Бодай тебе чорти вхопили! 
<li>Ах ти свиняче рило! 
<li>Ти, гімно нероздушене! 
<li>Най би ті всі зуби випали крім одного, який би тобі болів усе життя. 
<li>Комбінація тобі з трьох пальців! 
<li>Дати джмелика  

Письменниця Анастасія Мельниченко каже, що характер української нецензурної лексики має «анальний» характер, на відміну від російської, яка має яскраво виражений «генітальний» акцент: «У нас все через «сраку». Проте я не вважаю українські вислови про сраку бодай лайливими. Людям хочеться іноді «смачно» сказати, але звучить це навіть якось не образливо, по-доброму. Одразу видно: у нас до сраки ставляться з повагою». 

<li>Не плети гівно дівці в коси (не наговорюй) 
<li>Пасує, як в сраці фіранки! 
<li>Пішов, як за море срати (коли довго когось нема) 
<li>А там того шумовиння, як на сраці ластовиння (про хайпову інформацію, яку важко чи неможливо перевірити)  

Мовознавець Олександр Авраменко розповідає: «Серед лайливих слів українських не так багато, але вони є. Вони більше пов'язані з ректальної зоною або використовуються слова «свиня», «дідько» або «чорт». Наприклад, «дідька лісого» – це класична українська лайка». Він висловився проти матюків у публічних виступах і в ЗМІ. 

Від себе додамо, що деякі члени нашої команди іноді лаються отак (чули на власні вуха!): дідько, халепа, холєра або холєра ясная.  

Проте, звичайно, ми погоджуємось із психологами, що варто навчитися працювати із своїми емоціями і вчитися висловлювати те, що складно сформулювати. `,
        taskTitle: `Завдання`,
      task: `Усім: прочитайте уривок “Енеїди” Івана Котляревського. Додаткове завдання для тих, кому 18+: можете сьогодні трохи полаятись українською.  `,
    },
    {
      id: "14",
      pathUrl: "794308",
      name: `Крок 14. Жартуйте українською `,
      subheader: "",
      text: `Жартуйте, створюйте і поширюйте меми українською мовою. Тут нам дуже хочеться додати, що жарти є різні - добрі і злі. Ми не підтримуємо поширення жартів і мемів, які роздмухують ворожнечу за ознаками раси, статі, віросповідання, кольору шкіри, громадянства, етнічної приналежності, мови тощо. `,
        text2:`Меми - це значно глибше поняття, ніж смішна картинка з написом. Більше ви можете прочитати тут: <a href="https://uacrisis.org/uk/kulturovirusy"> https://uacrisis.org/uk/kulturovirusy</a> Однак ми нині хочемо вас познайомити із ресурсами, де ви можете читати жарти і дивитись меми українською мовою.  

<li>Гумор українською <a href="https://www.facebook.com/groups/mememeua/" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://www.facebook.com/groups/mememeua/</a>
<li>ГУ <a href="https://www.facebook.com/nohumor.in.ua" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;"> https://www.facebook.com/nohumor.in.ua </a>
<li>Чумусь ("Файні та придуркуваті меми українською") <a href="https://www.facebook.com/Chumuss/"> https://www.facebook.com/Chumuss/</a>
<li>I'm Not Even Sorry <a href="https://www.facebook.com/NotEvenSorryPage/" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://www.facebook.com/NotEvenSorryPage/</a>
<li>kozak.memes <a href="https://www.instagram.com/kozak.memes/" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://www.instagram.com/kozak.memes/</a>
<li>(Контент 18+) Безсоромники International <a href="https://www.facebook.com/groups/203209764937846" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://www.facebook.com/groups/203209764937846</a> 

Перед тим, як поширити мем російською, подумайте, чому б не зробити це українською. А й справді, чому? Самому чи самій взяти і зробити мем можна на цьому сайті: <a href="https://nohumor.in.ua/">https://nohumor.in.ua/</a> або ж на інших ресурсах, як от, наприклад, canva.com абощо. 

Як створювати наукові меми для уроків <a href="https://osvitoria.media/experience/yak-stvoryuvaty-naukovi-memy-dlya-urokiv/" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://osvitoria.media/experience/yak-stvoryuvaty-naukovi-memy-dlya-urokiv/</a> 

Як використовувати при підготовці до ЗНО меми <a href="https://osvitoria.media/experience/bombezno-8-krutyh-metodiv-dlya-pidgotovky-do-zno-z-ukrayinskoyi/" style=" -webkit-box-orient: vertical;  overflow: hidden; display: -webkit-box; width: 90%;">https://osvitoria.media/experience/bombezno-8-krutyh-metodiv-dlya-pidgotovky-do-zno-z-ukrayinskoyi/ </a>  `,
        taskTitle: `Завдання`,
      task: `Поділіться вашими улюбленими мемами українською мовою або перекладіть їх на українську, якщо вони іншими мовами.  `,
    },
    {
      id: "15",
      pathUrl: `383397`,
      name: `Крок. 15. Запам’ятовуйте українською`,
      subheader: "",
      text: `Сьогодні будемо говорити про те, як запам’ятати складні слова, фрази, наголоси тощо. 
      
Вебінар “Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка" проведе Мар'яна Лелик - письменниця, літературна редакторка, тренерка з ейдетики та мнемотехніки. Початок о 12.00.  

Трансляція: <a href="https://youtu.be/pBb9LqBGIgg" style="color:#58a6ff">https://youtu.be/pBb9LqBGIgg</a>   `,
        text2:`Ейдетика та мнемотехніка (мистецтво запам'ятовувати) - це сукупність прийомів та способів, які спрощують запам'ятовування, полегшують пригадування і покращують настрій.

Для того, щоб  запам'ятати будь-який список, важливо поєднати між собою всі пункти за допомогою візуальних образів та історії. Щоб пам'ятати, що Берлін - столиця Німеччини, а не Германії, не плутати російську та українську географічні назви, використовуємо асоціації: Герман і я поїхали в Німеччину, а там зустріли Бера і Ліну. 

Інший приклад про те, як запам’ятати послідовність кольорів веселки: червоний, оранжевий (помаранчевий), жовтий, зелений, блакитний, синій, фіолетовий. Ось декілька спеціальних мнемонічних фраз, однак ви можете придумати свої: 

<li>Чому Пінгвіни Живуть Зимою Без Своїх Фантазій 
<li>Чудові Пейзажі Житомирщини Зблизька Були Страшенно Фантастичними 
<li>Чи Одслонить Жінка Зранку Бісову Свою Фіранку? 
<li>Чапля Осінь Жде Завзято, Буде Сани Фарбувати 
<li>Чарівна Оленка Живе у Заростях Біля Старого Фонтану 
<li>Чабан Отару Жваво Заганяє, Бо Скоро Фільм 
<li>Чи Обжене Жвавенького Зайчиська Байдикуватий Ситий Фокстер'єр `,
        taskTitle: `Завдання`,
      task: `На вибір: 
<dt>&emsp;  1)Напишіть три міста чи країни, в яких ви хотіли б побувати, і які українською звучать по-іншому, ніж російською (наприклад, - мрію поїхати у Відень, відвідати Німеччину тощо). </dt>
<dt>&emsp;  2)Напишіть, які продукти потрібно купити, щоб приготувати вашу улюблену страву, напишіть 5 дієслів, які допоможуть її приготувати (потушкувати, порізати, обсмажити, відварити тощо). </dt>
<p></p>
Обов’язкове завдання: Ми уже подолали половину Мовомаратону! Вітаємо вас! Похваліть себе за досягнення - купіть собі бургер, шоколадку чи молочний коктейль! `,
    },
    {
      id: "16",
      pathUrl: `052144`,
      name: "Крок 16. Рахуйте українською ",
      subheader: "",
      text: `Поговорімо про числа. Чому п’ятдесяти, а не п’ят<span id="headline">и</span>десяти? 

Відповідь на запитання дуже проста — тому що у числівниках (тобто словах, що називають числа), які позначають десятки, ми відмінюємо <span id="headline">лише другу частину</span>, перша лишається незмінною. Наприклад: п’ятдесяти (копійок), шістдесяти двох днів (відпустки), сімдесятьом (працівникам), вісімдесятьох (учасників події), а <span id="headline">не</span> п’ятидесяти, шестидесяти. `,
     text2: `Звучить ніби й складно, але якщо заглибитись у відмінювання числівників, виходить ось який принцип. Кількісні числівники (тобто ті слова, що відповідають на запитання «скільки?») змінюються за відмінками й узгоджуються з іншими словами у реченнях. 

Числівники від <span id="headline">п'яти</span> до <span id="headline">двадцяти, тридцять, п’ятдесят, шістдесят, сімдесят, вісімдесят</span>, в усіх відмінках, крім називного, мають <span id="headline">паралельні форми</span>.  

У складних числівниках на позначення десятків (п’ятдесят, шістдесят, сімдесят, вісімдесят) відмінюється <span id="headline">тільки друга частина:</span> 
<div style="text-align-last: center;display: table;
    text-align: center;"> <img  style="width: 240px" src=${process.env.PUBLIC_URL}/static/images/marathon/taskData/day16_1.png></img>
<img  style="width: 240px" src=${process.env.PUBLIC_URL}/static/images/marathon/taskData/day16_2.png></img>
</div>
У складних числівниках на позначення сотень (двісті, триста, чотириста, п’ятсот, шістсот, сімсот, вісімсот, дев’ятсот) відмінюємо <span id="headline">обидві частини</span>. Тобто <span id="headline">не</span> п’ятсот (студентів не вистачило на факультеті), а <span id="headline">п’ятисот</span> студентів. 
<div style="text-align-last: center;display: table;
    text-align: center;"> <img  style="width: 240px" src=${process.env.PUBLIC_URL}/static/images/marathon/taskData/day16_3.png></img>
<img  style="width: 240px" src=${process.env.PUBLIC_URL}/static/images/marathon/taskData/day16_4.png></img>
</div>
У знахідному відмінку числівники мають форму називного відмінка (якщо іменник, із яким сполучається числівник, означає неістоту) або форму родового відмінка (якщо цей іменник означає істоту). 

У <span id="headline">складених кількісних числівниках відмінюємо всі частини</span>: з чотирьохсот сімдесяти восьми тисяч шестисот п’ятдесяти дев’яти (гривень). 

Числівники <span id="headline">сорок, дев’яносто, сто</span> в родовому, давальному, орудному й місцевому відмінках мають закінчення <span id="headline">-а</span>: сорока, дев’яноста, ста.  

<span id="headline">Дробові числівники</span> відмінюємо як звичайні числівники: двом третім, трьома п’ятими; ділити на одну двадцяту. Половина (1 ⁄ 2), третина (1 ⁄ 3), чверть (1 ⁄ 4) відмінюємо як іменники.   

Числівники <span id="headline">півтора</span> (відра, кілограма), <span id="headline">півтори</span> (доби, тисячі), <span id="headline">півтораста</span> невідмінювані. 
 
Дуже важливо пам’ятати, що при <span id="headline">дробових числівниках</span>, які означають кількість частин одного предмета, іменники вживаємо <span id="headline">в родовому відмінку однини</span> (ставимо питання кого? чого?): півтора метра (чого? родовий відмінок), двох третіх склянки (чого? родовий відмінок), однієї десятої відсотка (чого? родовий відмінок). 
 
Але: <span style="font-style: italic">  два з половиною місяці, три з половиною роки </span> (під час узгодження іменник пов’язуємо із цілим числом).  
 
Джерела: <a href='https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf' style="color:#58A6FFFF"> Український правопис </a>(2019), <a href="https://lcorp.ulif.org.ua/dictua/" style="color:#58A6FFFF"> Словники України</a>  `,
        taskTitle: `Завдання`,
      task: "Складіть список покупок українською мовою. І складайте його українською й надалі. ",
    },
    {
      id: "17",
      pathUrl: `492590`,
      name: "Крок 17. Мандруйте Україною ",
      subheader: "",
      text: `Топоніми - це власні географічні назви. Як правильно - Кременчук чи Кременчуг? Українські географічні назви пишемо за нормами українського правопису так: (назви населених пунктів) Вінниця, Гребінка, Донецьк, Дубно, Запоріжжя, <span id="headline">Кам’янець-Подільський</span>, Кропивницький, Київ, Козятин, <span id="headline">Кременчук</span>, Кривий Ріг, Львів, Миколаїв, Новгород-Сіверський, Одеса, Охтирка, <span id="headline">Рівне</span>, Трипілля, Харків, Чернівці, Чернігів, Шепетівка, Ямпіль; (назви водойм) Дніпро, Дністер, Західний Буг, Прип’ять, Рось, Тетерів, Тиса, Трубіж.`,
        text2: `У назвах українських населених пунктів треба розрізняти <span id="headline">-поль</span> у словах грецького походження (що з грецької означає «місто»): Маріу<span id="headline">поль</span>, Меліто<span id="headline">поль</span>, Ніко<span id="headline">поль</span>, Севасто<span id="headline">поль</span>, Сімферо<span id="headline">поль</span> і <span id="headline">-піль</span> (з українського «поле»): Борис<span id="headline">піль</span>, Крижо<span id="headline">піль</span>, Терно<span id="headline">піль</span>. 
 
У травні 2017 року Верховна Рада <a href="https://www.pravda.com.ua/news/2017/05/23/7144783/" style="color:#58A6FFFF;  text-decoration-line: underline"> перейменувала</a> місто <span id="headline">Мукачеве</span> на <span id="headline">Мукачево</span>. Ця власна назва відмінюється так, як іменник із відповідним закінченням, а не за прикметниковим типом, тому Мукачева, (а не Мукачевого), Мукачеву (а не Мукачевому), у Мукачеві (а не у Мукачевому). 
 
Важливо пам'ятати, що під впливом російської мови багато людей називають один із районів Києва «<span id="headline">Голосієво</span>», але українською правильно говорити «<span id="headline">Голосіїв</span>». За аналогією інші власні назви: Київ, Миколаїв, <span id="headline">Пирогів</span> (а не <span id="headline">Пирогово</span>). 
<div style="text-align: -webkit-center;"> <img  style="width: 75%; align-self: center" src=${process.env.PUBLIC_URL}/static/images/marathon/taskData/day17_1.png></img></div>
Відповідно відмінювання відбуватиметься за спільним принципом: Києва, Голосієва, Миколаєва, Пирогова, у Києві, Голосієві, Пирогові, Миколаєві.  

Географічні назви відмінюються за принципами іменників (відповідають на запитання хто? що?), наприклад: 

Лóхвиця — Лóхвиці, Лóхвицею, у Лóхвиці; Одеса — Одеси, в Одесі; Полтава — Полтави, у Полтаві; Луганськ — Луганська, Луганськом, у Луганську; Львів — Львова, Львовом, у Львові; Світязь (озеро) — Світязем, на Світязі; Токмак — Токмака, Токмакам, у Токмаці (у Токмаку); Ужгород — Ужгорода, Ужгородом, в Ужгороді; Керч — Кéрчі, Кéрчю, у Кéрчі. 

Географічні назви, що мають форму множини, відмінюємо як відповідні загальні іменники: Лубни — Лубен, Лубнам, у Лубнах; Прилуки — Прилук, Прилукам, у Прилуках; Ромни — Ромен, Ромнам, у Ромнах; Чернівці — Чернівців, Чернівцям, у Чернівцях.  

Географічні назви із прикметниковими закінченнями (тобто ті, що відповідають на запитання який? яка? яке?) відмінюємо як прикметники: Рівне — Рівного, Рівному, у Рівному. 

Але слід відрізняти подібні назви із закінченням -а, що відмінюються як іменники: Сміла, Сміли (а не Смілої), Смілі, Смілою, у Смілі.  

Географічні назви, що складаються з прикметника та іменника чи, навпаки, іменника та прикметника, відмінюємо в обох частинах: Гола Пристань — Голої Пристані; Асканія-Нова — Асканії-Нової, в Асканії-Новій; Кам’янець-Подільський — Кам’янця-Подільського, у Кам’янці-Подільському. 

Географічні назви, що складаються з двох іменників відмінюємо лише в другій частині: Івано-Франківськ — Івано-Франківська, в Івано-Франківську; Михайло-Коцюбинське — Михайло-Коцюбинського, у Михайло-Коцюбинському. Але: Конча-Заспа — Кончі-Заспи, у Кончі-Заспі; Пуща-Водиця — Пущі-Водиці, у Пущі-Водиці. 

Джерела:  <a href="https://mon.gov.ua/storage/app/media/zagalna%20serednya/05062019-onovl-pravo.pdf" style="color:#58A6FFFF;">Український правопис</a> (2019),  <a href="https://ukr-mova.in.ua/library/antusurzhuk/u-golosievo,-u-golosievomu-chu-v-golosievi" style="color:#58A6FFFF;">Мова — ДНК нації </a> `,
        taskTitle: `Завдання`,
      task: `Виправте помилки у тексті: <span style="font-style: italic">Я живу у Голосієво, недалеко від Пирогово, проте сам родом з Кременчуга. Моя дівчина - зі Смілої Черкаської області. Недавно ми їздили у Ровенську область на фестиваль, дуже сподобалось. Звідти було недалеко до Львову, тож заїхали і туди. Дуже гарне місто.</span> `,
    },
    {
      id: "18",
      pathUrl: `530544`,
      name: "Крок 18. Співайте українською ",
      subheader: "",
      text: `Олена Жупанова, учителька англійської мови для дітей, письменниця, авторка блогу “Вчителька в рожевій спідничці”, радить співати пісні і таким чином вивчати мову: 
`,
        text2: `      “Чому дуже добре вивчати слова і вирази у піснях і чому викладачі рекомендують так робити? Будь-які нові слова чи вирази вам найкраще вивчати у контексті, а не ізольовано від інших. Тоді ви бачите, як ці слова поводять себе у мові і як з'єднуються з іншими словами. Звідки ми беремо цей контекст? Його нам дає текст і його нам дає пісня. Ми беремо якийсь масив слів, які між собою уже сполучені, як в реальній мові, і утворюють якийсь смисл. Це і є контекст. Отже, у нас є тексти і є пісні. Чому пісні дуже ефективні? По-перше, пісня має мелодію, ритм. По-друге, пісня має риму. Ритм і рима сприяють тому, що ви дуже добре запам'ятовуєте вирази, слова, загалом всю пісню. Вони краще запам'ятовуються людським мозком, ніж текст сам по собі”. `,
        taskTitle: `Завдання`,
      task: `Оберіть улюблену українську пісню, знайдіть текст і проспівайте її разом з виконавцем. Поділіться своїми улюбленими піснями сучасних виконавців. `,
    },
    {
      id: "19",
      pathUrl: `231847`,
      name: "Крок 19. Шукайте українську ",
      subheader: "",
      text: "Зверніть увагу, чи є українська мова на панелі приладів у вашому авто, ноутбуці, смартфоні, пральній машині, мультиварці, інструкції до ліків. Часто достатньо лише звернути увагу на це у продавця і він надасть вам ноутбук із ліцензійною операційною системою, де уже встановлена українська мова. ",
        text2: `Тиждень практики продовжується, тож ми підготували для вас декілька коротких відео освітньої платформи Є-мова, з яких дізнаєтесь, що в українській мові слова біль, нежить, тюль, ступінь, дріб, степ та літопис належать до чоловічого роду, а слово путь до жіночого. Лекції вчительки української мови і літератури Оксани Гордійчук: 

Морфологія та синтаксис, або Як приборкати дракона граматики? (Частина 1) <a href="https://youtu.be/QLHFk8zyN4U" style="color:#58A6FFFF;">https://youtu.be/QLHFk8zyN4U </a>  

Морфологія та синтаксис, або Як приборкати дракона граматики? (Частина 2) <a href="https://youtu.be/O80ejaPbw0U" style="color:#58A6FFFF;">https://youtu.be/O80ejaPbw0U  </a>

 `,
        taskTitle: `Завдання`,
      task: "Подивіться, якою мовою написи на вашій пральній машині, мультиварці чи іншому побутовому приладі. Якщо вони не українською, перекладіть їх.",
    },
    {
      id: "20",
      pathUrl: `117384`,
      name: "Крок 20. Не бійтесь зазирати у словник ",
      subheader: "",
      text: `Яка різниця між “ставитись” і “відноситись”? Ставитися — це виявляти ставлення до когось, до чогось, а відноситися — перебувати в певній відповідності, співвідношенні з чим-небудь (насамперед у математиці). “Як ви до цього ставитеся?”, “A відноситься до B, як X до Y”.  
       `,
        text2: `Корисні ресурси, які вам допоможуть:

Словники:  

Тут зібрані майже два десятки словників, пошук працює одразу по всіх (збережіть собі у закладках!): <a href="https://r2u.org.ua" style="color:#58A6FFFF;">https://r2u.org.ua</a>
Тут зручно шукати синоніми <a href="https://ukrainskamova.com/publ/slovnik_sinonimiv/21" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://ukrainskamova.com/publ/slovnik_sinonimiv/21</a>  

Перевірити текст: 
<a href="https://ukr-mova.in.ua/perevirka-tekstu" style="color:#58A6FFFF;">https://ukr-mova.in.ua/perevirka-tekstu </a>
<a href="https://onlinecorrector.com.ua/uk/" style="color:#58A6FFFF;">https://onlinecorrector.com.ua/uk/ </a>
<a href="https://r2u.org.ua/check" style="color:#58A6FFFF;">https://r2u.org.ua/check </a>

Сьогодні відбудеться <span id="headline">онлайн-розмова з викладачкою української мови. Тема: Фемінітиви, проєкт і маратон. Все, що ви хотіли спитати, але боялись</span>. Наталія Вуйтік – викладачка української мови, вчителька “Всеукраїнської школи онлайн”, перекладачка.  

Коли: 7 вересня о 12:00. 
Трансляція: <a href="https://youtu.be/U0aE0cpeRao" style="color:#58A6FFFF;">https://youtu.be/U0aE0cpeRao</a> `,
        taskTitle: `Завдання`,
      task: `Перекладіть на українську слова і складіть з ними речення. Скористайтеся словником r2u. 

<dt>&emsp;1) интересный человек </dt>
<dt>&emsp;2) взбудораживать </dt>
<dt>&emsp;3) изумлять </dt>
<dt>&emsp;4) девичник  </dt>
<dt>&emsp;5) холостяк </dt>
<dt>&emsp;6) ухаживать </dt>
<dt>&emsp;7) галдёж </dt>`,
    },
    {
      id: "21",
      pathUrl: `040037`,
      name: "Крок 21.Слухайте радіо і подкасти українською",
      subheader: "",
      text: `Сьогодні ми хочемо порадити вам встановити мобільний застосунок suspilne.radio, де є записи програм на різний смак, аудіокниги і радіоп’єси українською мовою. 

 `,
        text2: `Завантажити можна тут: <a href="https://play.google.com/store/apps/details?id=suspilne.radio.ua" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%;color:#58A6FFFF;">https://play.google.com/store/apps/details?id=suspilne.radio.ua</a>  
або тут: 
<a href="https://apps.apple.com/ua/app/suspilne-radio/id1437432109" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%;color:#58A6FFFF;">https://apps.apple.com/ua/app/suspilne-radio/id1437432109</a>  

У застосунку ви можете обрати одну із трьох програм Суспільного радіо. На радіо Культура у вкладці “Програми” можна послухати аудіокнижки, радіоп’єси.`,
        taskTitle: `Завдання`,
      task: `Поділіться у чаті або соцмережах вашою улюбленою радіо програмою чи подкастами українською мовою. Розкажіть, чому вона вам подобається. Не забудьте про хештеги #Мовомаратон і #МоваНезалежності. `,
    },
    {
      id: "22",
      pathUrl: "250258",
      name: "Крок 22. Читайте українські книжки",
      subheader: "",
      text: "Читання українською мовою дуже помічне не лише для збільшення словникового запасу, а й просто для приємного проведення часу. Спочатку ми хотіли порадити якісь конкретні книжки, але їх так багато, що наш список вийшов би таким довжелезним - і ви б його не читали. Тож ми зібрали для вас ресурси, з яких можна дізнатись, що нового українською з’явилось в книгарнях і де шукати книжки українською.",
        text2: `<span id="headline">Читомо</span> <a href="https://chytomo.com/" style="color:#58A6FFFF;">https://chytomo.com/</a> 
“Це медіа про книгу в усіх її проявах і про осмислене читання як трамплін, інструмент для самоосвіти та реалізації”. Тут ви можете дізнатися про свіженькі книжки, видані українською мовою, про лауреатів Букерівської премії, про те, що читають зірки, а ще списки книжок для розвитку, про інформаційну безпеку та ін.

<span id="headline">Барабука</span> <a href="https://www.barabooka.com.ua/" style="color:#58A6FFFF;">https://www.barabooka.com.ua/</a> 
Тут чимало новин про те, що новенького з'явилось у дитячій літературі, розповіді про книжки для дітей, огляди дуже страшних книжок, поради, де купити дитячі книжки в Україні. А ще тут є "Шпаргалка для вчителя: літературне читання 1-4 клас" та проєкт #ЖивіПисьменники.

<span id="headline">Читаємо книги разом з дітьми</span><a href="https://www.facebook.com/groups/393191144554346" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.facebook.com/groups/393191144554346</a> Група у Facebook, де учасники діляться новинками та враженнями про книжки для дітей.

<span id="headline">ВРАЖЕННЯ UA</span> | Література українською, читання, книги та фільми, серіали <a href="https://www.facebook.com/groups/vrajenniya.ua" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.facebook.com/groups/vrajenniya.ua</a> Група у Facebook, у якій учасники пишуть відгуки про прочитані книжки українською мовою.

<span id="headline">ЛітАкцент</span> <a href="http://litakcent.com/ " style="color:#58A6FFFF;">http://litakcent.com/</a>
Як вони самі про себе кажуть, це видання, покликане бути провідником у світі сучасного письменства. Цікаве для тих, хто більш-менш систематично відвідує книгарні, хто має смак до гарної літератури і хоче зіставити свої оцінки новинок із судженнями літературознавців.

<span id="headline">Книжковий блог Yakaboo</span> <a href="https://blog.yakaboo.ua/" style="color:#58A6FFFF;">https://blog.yakaboo.ua/</a>
Тут ви знайдете поради, що почитати, дізнаєтесь про книжкові новинки, цікаві факти про сучасних письменників - українських і не тільки, усе про віммельбухи (книжки-картинки) і книжки з червоними обкладинками.

<span id="headline">Блог Видавництва Старого Лева</span> <a href="https://starylev.com.ua/club" style="color:#58A6FFFF;">https://starylev.com.ua/club</a>
Історія однієї обкладинки, розповіді про книжки, книжкові тести, уривки із книг, поради щодо зацікавлення дітей читанням - це все тут є.

<span id="headline">Друг читача</span> <a href="https://vsiknygy.net.ua/" style="color:#58A6FFFF;">https://vsiknygy.net.ua/</a>
Тут є відеоогляди книжок, розмови з авторами, тести на кшталт "Відгадай письменника за цитатою" та інше цікаве довколалітературне.

<span id="headline">Блог Книгарні "Є"</span> <a href="https://book-ye.com.ua/blog/" style="color:#58A6FFFF;">https://book-ye.com.ua/blog/</a>
Тут можна почитати про новинки книжкового світу, думки про літературу, про лауреатів Нобелівської премії тощо.
`,
        taskTitle: `Завдання`,
      task: "Пам’ятаєте дитячу розвагу ворожити на книгах? Ставите запитання, яке вас турбує, тоді кажете вголос сторінку і номер рядка. Сьогодні пограємось: візьміть будь-яку книжку українською мовою, яка у вас є, відкрийте сторінку 30, рядок 10, прочитайте відповідь на запитання «Про що буде наступний вебінар Мовомаратону» і поділіться в чаті, що у вас вийшло."
        +" <br><br> А якщо серйозно, то напишіть допис або поділіться в чаті назвою книги, яку зараз читаєте. Звичайно, тією, яку читаєте українською. Не забудьте про хештеги #Мовомаратон і #МоваНезалежності.",
    },
    {
      id: "23",
      pathUrl: "874457",
      name: "Крок 23. Вивчайте українську історію",
      subheader: "",
      text: "Розуміння історії допоможе зрозуміти процеси, що відбуваються зараз, в тому числі і дати відповідь на питання “чому ми так говоримо”."
        + "<br><br>ХХ століття було для українців переломним як в особистому, так і в загальнонаціональному сенсі. На початку століття розпалися дві великі імперії, на руїнах яких постала нова українська держава. У 1917-1921 роках українці змогли отримати першу незалежність, яка хоч і тривала недовго, проте вплинула на все подальше століття. Радянська окупація принесла репресії, депортації, голодомори. Друга світова принесла нові втрати, розруху та зламані долі. 30 років відновленої незалежності відкрили історичні архіви, а з ними й відроджується національна пам’ять, яку десятиліттями намагалися або приховати, або спотворити. Кожен з нас має власні родинні історії, багато з них потребують архівної реконструкції. Це історії доль рідних нам людей, за кожною стоять конкретні імена. У 2015 році було відкрито архіви, які раніше були недоступні.",
        text2: `Посібник “Право на правду” допоможе відповісти на запитання, де знайти відомості про родичів, засуджених у часи СРСР, з чого почати пошук і як правильно написати запит в архів:
        <a href="https://cdvr.org.ua/sites/default/files/archive/poradnyk-2015-el.pdf" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://cdvr.org.ua/sites/default/files/archive/poradnyk-2015-el.pdf</a>
        Ми нарешті маємо незалежну державу, збільшився рівень добробуту і безпеки, можемо подорожувати за кордон і спілкуватися, незважаючи на відстані. Це унікальний час!
        
        <span id="headline">Цікаві книжки з історії України (радить Тетяна Швидченко, кандидатка історичних наук):</span>
        <dt>&emsp;1. Українська мова у XX сторіччі: історія лінгвоциду</dt>
        <dt>&emsp;2. Дід Свирид. Історія України від діда Свирида (3 книги)</dt>
        <dt>&emsp;3. Мальована історія Незалежності України</dt>
        <dt>&emsp;4. Володимир В\`ятрович. Україна. Історія з грифом «Секретно»</dt>
        <dt>&emsp;5. Історія без цензури, 10 книжок</dt>
        <dt>&emsp;6. Василь Шкляр. Чорний ворон. Залишинець</dt>
        <dt>&emsp;7. О. Зінченко, В. В'ятрович, М. Майоров. Війна і міф. Невідома Друга світова</dt>
        <dt>&emsp;8. Дмитро Шурхало "Скоропадський, Маннергейм, Врангель: кавалеристи-державники"</dt>
        <dt>&emsp;9. Ґарет Джонс. Людина, яка забагато знала</dt>
        <dt>&emsp;10. Іван Багряний. Сад Гетсиманський</dt>
        Тести від Радіо Свобода, щоб перевірити свої знання історії:
        <ul>
            <li><a href="https://www.radiosvoboda.org/a/test-davni-ukrayinski-slova/31131908.html" style="color:#58A6FFFF;">Чи знаєте ви популярні давні українські слова?</a></li>
            <li><a href="https://www.radiosvoboda.org/a/28019023.html" style="color:#58A6FFFF;">Що ви знаєте про Михайла Грушевського?</a></li>
            <li><a href="https://www.radiosvoboda.org/a/27959502.html" style="color:#58A6FFFF;">Що ви знаєте з історії України?</a></li>
            <li><a href="https://www.radiosvoboda.org/a/27942505.html" style="color:#58A6FFFF;">Що ви знаєте про Незалежну Україну?</a></li>
            <li><a href="https://www.radiosvoboda.org/a/27077050.html" style="color:#58A6FFFF;">Чи склали б ви ЗНО з історії України?</a></li>
        </ul>`,
        taskTitle: `Завдання`,
      task: "На вибір <br>"
        +"<dt>&emsp;1) Дослідіть, яке ім'я було у вас найпопулярніше в родині протягом століття (жіноче і чоловіче).</dt>"
        +"<dt>&emsp;2) Дослідіть, які прізвища були у вашому роду у чотирьох-п’яти поколінь. Поширення того чи іншого прізвища можна подивитися тут - <a href=\"https://ridni.org/karta/\" style=\"color:#58A6FFFF;\">https://ridni.org/karta/</a></dt>",
    },
    {
      id: "24",
      pathUrl: "277038",
      name: "Крок 24. Пізнавайте українське мистецтво",
      subheader: "",
      text: "Нині ми будемо з’ясовувати, як говорити з дітьми про мистецтво, власне, на прикладі українських художників.",
        text2: `Дивіться вебінар з Оксаною Садовенко, художницею та викладачкою для дітей, лекторкою освітньої організації Культурний Проект та викладачкою художньої студії Art4kid, авторкою книги для дітей "Ти і Малевич". <br>
        Трансляція: <a href="https://youtu.be/AbTeCSbs_oc" style="color:#58A6FFFF;">https://youtu.be/AbTeCSbs_oc</a>
        Початок 11 вересня об 11:00. <br>
        Для тих, хто любить читати ми знайшли два матеріали:
        <ul><br><li><a href="https://uchoose.info/avangardna-ukrayina/" style="color:#58A6FFFF;">Про український авангард</a></li><li><a href="https://www.radiosvoboda.org/a/bilokur-%D1%81hudozhnytsya-10-faktiv/30988342.html" style="color:#58A6FFFF;">Про Катерину Білокур</a></li></ul>`,
        taskTitle: `Завдання`,
      task: "На вибір:\n"
        +"<dt>&emsp;1) Почитайте разом з дітьми вголос книжку українською мовою.</dt><br>"
        +"<dt>&emsp;2) Напишіть у кількох реченнях, щоб зображено на картині і які емоції вона у вас викликає.</dt>"
        +"<br>Картина сучасної художниці Катерини Косьяненко “<a href=\"https://katerynko.com.ua/gallery/zhyvopys/2019-perehid/\" style=\"color:#58A6FFFF;\">Перехід</a>” (2019)"
        +"<div style=\"text-align: -webkit-center;\"> <img style=\"width: 90%; align-self: center\" src=\"https://katerynko.com.ua/wp-content/uploads/apg_gallery/2020/4/full_0_0902-norajpg_336544.jpg\" class=\"fr-content fr-content-image\"> </div><br>",
    },
    {
      id: "25",
      pathUrl: "393279",
      name: "Крок 25. Грайте ігри українською",
      subheader: "",
      text: "Сьогодні ми зібрали для вас ігри для дітей і підлітків (та й для дорослих вони теж підійдуть), пов’язані із мовою.",
        text2: `<span id="headline">"Слово"</span>
        Учасник гри виходить за двері. Решта загадують слово (грайте українською мовою!) й об’єднуються у таку кількість команд, скільки складів у даному слові. Коли заходить учасник гри, команди в один голос кричать кожен свій склад. Потрібно вгадати задумане слово.        
        <br><span id="headline">"Алфавіт навколо нас"</span>
        Ведучий пропонує 2 командам написати за абеткою протягом 5-10 хвилин назви навколишніх предметів і речей, що знаходяться в кімнаті чи приміщенні. Можна писати по кілька предметів на одну букву. Головні труднощі - відшукати предмети на всі букви алфавіту. Переможе той, хто складе найдовший список слів. До того ж діти повинні обов'язково показати, де знаходиться названий предмет.
        <br><span id="headline">"МСП"</span>
        Учаснику потрібно вгадати, що таке "МСП". "МСП" - це абревіатура фрази "мій сусід праворуч". Один учасник гри ставить всім решта по черзі за годинниковою стрілкою питання, на які можна відповісти "так" або "ні". Гравці зобов'язані відповідати правду, але відповідають тільки про свого сусіда праворуч.
        <br><span id="headline">“Пів слова за вами”</span>
        Учасники гри сідають у коло і перекидають один одному м’яч. При цьому той, хто кидає, голосно говорить половину якого-небудь слова, а той, хто ловить, повинен назвати другу половину. Наприклад, теле-фон, чіп-си. Кидати м’яч можна будь-якому гравцю. Відповідати потрібно швидко. За кожну помилку чи затримку гравець вибуває з гри. Умови гри можна ускладнити. Ведучий дає (говорить) половину слова кожному, хто сидить у колі. І потрібно продовжити другу половину. Не може - штрафний бал. Ведучий починає говорити свою половину слова, наприклад: “теле…”, а діти продовжують: телефон, телескоп, телевізор і тд.
        <br><span id="headline">“Газетна замітка”</span>
        Із заголовків різних старих газет і журналів (їх перед тим необхідно вирізати), учасники повинні скласти коротку гумористичну розповідь, оголошення чи рекламу. Для цього потрібні папір, клей, пензлик і почуття гумору.
        <br>Добірка ресурсів, де можна знайти ігри:<br>
        72 гри, у які грають пластуни (і не лише): <a href="https://plastovabanka.org.ua/igry/" style="color:#58A6FFFF;">https://plastovabanka.org.ua/igry/</a><br>
        Місце, де дуже багато ігор для дітей, юнацтва та молоді:<a href="https://www.plast.org.ua/repository-activities/" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.plast.org.ua/repository-activities/</a>
        У розділі Виховні матеріали - Методика ви можете ознайомитись з іграми, у які грають в Спілці української молоді: <a href="https://cym.org/materials/" style="color:#58A6FFFF;">https://cym.org/materials/</a>
        <br>55 ігор, які можна грати в приміщенні: <a href="https://pedpresa.com.ua/201548-55-energijnyh-igor-vdoma.html" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://pedpresa.com.ua/201548-55-energijnyh-igor-vdoma.html</a>`,
        taskTitle: `Завдання`,
      task: "Якщо ваша улюблена гра не українською, перекладіть її на українську і поділіться з нами.",
    },
    {
      id: "26",
      pathUrl: "610430",
      name: "Крок 26. Заведіть нотатник для нових цікавих слів, фразеологізмів, крилатих висловів",
      subheader: "",
      text: "Щоб мовлення не було сухе і офіційне, в розмові ми користуємось фразеологізмами, метафорами, цитуємо класиків і згадуємо спільні жарти чи меми.",
        text2: `Фразеологізм - це усталений зворот, стійке поєднання слів, що виступає в мові як єдиний, неподільний і цілісний за значенням вислів. Наприклад: байдики бити (ледарювати, нічого не робити), вилами по воді писано (невідомо, як буде), сім п’ятниць на тиждень (легко й часто змінювати погляди).
        <ul>
        <li>Список найуживаніших фразеологізмів: <a href="https://bit.ly/3tq4oLT" style="color:#58A6FFFF;">https://bit.ly/3tq4oLT</a></li><li>Словник фразеологізмів: <a href="https://bit.ly/2X2G3QJ" style="color:#58A6FFFF;">https://bit.ly/2X2G3QJ</a></li></ul>`,
        taskTitle: `Завдання`,
      task: "Випишіть з десяток фразеологізмів, які ви вже використовуєте або використовують ваші знайомі. Виберіть зі словника мінімум три фразеологізми, які ви хотіли б вживати, але ще поки не використовуєте.",
    },
    {
      id: "27",
      pathUrl: "402802",
      name: "Крок 27. Слухайте українську музику",
      subheader: "",
      text: "Декілька років тому завдяки закону про мовні квоти на радіо частка української музики виросла з 5% до 50% і більше. Відбувся вибух української музики і точно є з чого вибрати. Сьогодні знайомимо вас з ресурсами, де можна дізнатися новинки української музики.",
        text2: `Радіо з лише українською музикою: Країна ФМ <a href="https://krainafm.com.ua/" style="color:#58A6FFFF;">https://krainafm.com.ua/</a> 
        <br>Яскраве радіо: <a href="http://yaskraveradio.fm/" style="color:#58A6FFFF;">http://yaskraveradio.fm/</a>
        <br>Вікенд Нової Української Музики на радіо "Промінь": <a href="http://www.nrcu.gov.ua/prog.html?id=851" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">http://www.nrcu.gov.ua/prog.html?id=851</a>
        Канал з дитячим піснями: З любов'ю до дітей <a href="https://www.youtube.com/channel/UCpa-I4ppRaNH433rA5GYW9g" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.youtube.com/channel/UCpa-I4ppRaNH433rA5GYW9g</a>
        Онлайн-журнал СЛУХ і його ютуб-канал: <a href="https://www.youtube.com/c/slukh-media/videos" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.youtube.com/c/slukh-media/videos</a>
        «Неформат» — онлайн-видання про українську якісну некомерційну музику: <a href="https://www.neformat.com.ua/" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.neformat.com.ua/</a>
        Медiа про нову українську музику LiRoom: <a href="https://liroom.com.ua/" style="color:#58A6FFFF;">https://liroom.com.ua/</a>
        <br>Слухай українське. Музика від виключно українських виконавців: <a href="https://www.facebook.com/listenUkrainian/" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://www.facebook.com/listenUkrainian/</a>
        Фільм "Червона рута. Як це було. 1989 рік": <a href="https://youtu.be/UZkQshpOoHw" style="color:#58A6FFFF;">https://youtu.be/UZkQshpOoHw</a>
        <br>Фільм "Вусатий фанк" <a href="https://bit.ly/3yR17qb" style="color:#58A6FFFF;">https://bit.ly/3yR17qb</a> 
        <br>Багато концертів є на Суспільне Культура <a href="https://bit.ly/3zSzTRu" style="color:#58A6FFFF;">https://bit.ly/3zSzTRu</a>`,
        taskTitle: `Завдання`,
      task: "Напишіть список п’яти або десяти ваших улюблених українських пісень (не народних!), які з’явились за 30 років Незалежності. Поділіться ним у чаті або в соцмережах. Не забудьте про хештеги #Мовомаратон і #МовНезалежності.",
    },
    {
      id: "28",
      pathUrl: "522997",
      name: "Крок 28. Підтримуйте українські спільноти",
      subheader: "",
      text: "Є низка значень терміну “соборність”. Найбільш вживані з них: об’єднання в одне державне ціле всіх земель, заселених конкретною нацією на суцільній території; духовна консолідація всіх жителів держави, згуртованість громадян, незалежно від їхньої національності. Нарешті, соборність невіддільна від державності, суверенітету й реальної незалежності народу – фундаменту для побудови демократичної держави.",
        text2: `Наша сила - в єдності. Підтримуючи один одного, ми стаємо сильніші.<br><br>Наш Мовомаратон підходить до завершення. Чотири тижні ми покращували свою українську, дивились відео українською, читали тексти і слухали українське. Проаналізуйте, чи збільшилась кількість українського контенту навколо вас, що пропонує вам ютуб, чи близькі і друзі помітили зміни.`,
        taskTitle: `Завдання`,
      task: "Познайомтесь з іншими учасниками Мовомаратону, які живуть у вашому місті. Поділіться одне з одним цікавими місцями у вашому населеному пункті, де можна почути українську. Чи матиме продовження ваш особистий мовомаратон через два дні?",
    },
    {
      id: "29",
      pathUrl: "740501",
      name: "Крок 29. Надихайтесь українською!",
      subheader: "",
      text: "НАДИХА́ТИСЯ - відчувати приплив сил, бадьорості, душевного піднесення під впливом чого-небудь." +
        "<br><br>Відео для натхнення: <a href=\"https://youtu.be/TW9BQ-5KFV0\" style=\"color:#58A6FFFF;\">https://youtu.be/TW9BQ-5KFV0</a>" +
        "<br>“Мені здається, час настав” <a href=\"https://youtu.be/zgSEdYwndMQ\" style=\"color:#58A6FFFF;\">https://youtu.be/zgSEdYwndMQ</a>",
        text2: `Сьогодні - передостанній день Мовомаратону і ми вже трохи сумуємо. Однак в епоху соцмереж ми можемо не зникати з життя один одного, а продовжувати творити спільноту, яка зветься Україна, шукати натхнення в українській мові і культурі, творити українською.`
        + '<br><br>А ще є нагода зустрітись наживо! У Києві на Володимирській гірці 19 вересня пройде перший <a href="https://www.facebook.com/events/2754499954695563" style="color:#58A6FFFF;">сімейний фестиваль української мови “Мовосфера”</a>. '
        + 'Під час фестивалю о 18.00 відбудеться Всеукраїнський танцювальний флешмоб "Танцюй під українське" Київ (Володимирська гірка), Львів, Дніпро (Фестивальний причал), Запоріжжя (Хортиця) із прямою трансляцією між містами, а також форум з популяризації мови для батьків за участю зірок (Тараса Тополі, Марічки Падалко).',
        taskTitle: `Завдання`,
      task: "Пам’ятаєте, у другий день Мовомаратону було завдання написати мотиваційний лист самому собі і відповісти на питання: що вас мотивує говорити українською? хто може вас підтримати? якою чи яким ви хочете бути після 30 днів Мовомаратону? Знайдіть цей лист, поверніться до нього. Що змінилось за ці чотири тижні? Допишіть цей лист. Якою чи яким ви хочете бути через пів року чи рік? Що вас мотивує говорити українською?"
        + "<br><br>Нам дуже важливий ваші відгук для того, щоб наступного разу ми зробили краще. Дуже просимо заповнити анкету та поділитись вашими враженнями:"
        + "<a href=\"https://forms.gle/hAsZcZ3BGDwtifeYA\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://forms.gle/hAsZcZ3BGDwtifeYA</a>",
    },
    {
      id: "30",
      pathUrl: "851612",
      name: "Крок 30. Відчуйте себе переможцями!",
      subheader: "",
      text: "Друзі, вітаємо! Відчуйте себе переможцями - ви подолали ці тридцять днів Мовомаратону до 30-річчя Незалежності України! Ви це змогли! Ми радіємо разом з вами! Сподіваємось, що ви отримали за ці тридцять днів цінне - досвід, практику і однодумців."
        + "<br><br>Протягом місяця чати в телеграмі будуть відкриті, тож ви завжди можете звернутись із запитаннями до нас, ми радо допоможемо."
        + "<br><br>Перемагайте, творіть і любіть українською!"
        + "<br><br>Ми зібрали корисні посилання, щоб вам було зручно.",
      text2: `<span id="headline">Вебінари:</span>`
        + '<br><br>Перейти на українську: виклики і можливості. Ольга Андрусенко <a href="https://youtu.be/Chvx7nQtIbE" style="-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;">https://youtu.be/Chvx7nQtIbE</a>'
        + "Голос і мовлення. Олександр Жуган <a href=\"https://youtu.be/keF34uPCHRk\" style=\"color:#58A6FFFF;\">https://youtu.be/keF34uPCHRk</a>"
        + "<br>Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка. Мар'яна Лелик <a href=\"https://youtu.be/pBb9LqBGIgg\" style=\"color:#58A6FFFF;\">https://youtu.be/pBb9LqBGIgg</a>"
        + "<br>Фемінітиви, проєкт і маратон. Все, що ви хотіли спитати у викладачки української мови, але боялись. Наталя Вуйтік <a href=\"https://youtu.be/U0aE0cpeRao\" style=\"color:#58A6FFFF;\">https://youtu.be/U0aE0cpeRao</a>"
        + "<br>Як говорити з дітьми про мистецтво. Оксана Садовенко <a href=\"https://youtu.be/AbTeCSbs_oc\" style=\"color:#58A6FFFF;\">https://youtu.be/AbTeCSbs_oc</a>"
        + "<br><br> <span id=\"headline\">Мовні курси</span>"
        + "<br><br> Освітня платформа “Є-мова”"
        + "<a href=\"https://emova.language-ua.online/\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://emova.language-ua.online/</a>"
        + "<a href=\"https://www.youtube.com/c/eMova/playlists\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://www.youtube.com/c/eMova/playlists</a>"
        + "<br><span id=\"headline\">Словники</span>"
        + "<br><br>Тут зібрані майже два десятки словників, пошук працює одразу по всіх: <a href=\"https://r2u.org.ua\" style=\"color:#58A6FFFF;\">https://r2u.org.ua</a>"
        + "<br>Тут зручно шукати синоніми <a href=\"https://ukrainskamova.com/publ/slovnik_sinonimiv/21\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://ukrainskamova.com/publ/slovnik_sinonimiv/21</a>"
        + "<br>Перевірити текст:"
        + "<a href=\"https://ukr-mova.in.ua/perevirka-tekstu\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://ukr-mova.in.ua/perevirka-tekstu</a>"
        + "<a href=\"https://onlinecorrector.com.ua/uk/\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://onlinecorrector.com.ua/uk/</a>"
        + "<a href=\"https://r2u.org.ua/check\" style=\"-webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 90%; color:#58A6FFFF;\">https://r2u.org.ua/check</a>",
      taskTitle: `Завдання`,
      task: "Подякуйте собі!",
    },
  ],
};
