module.exports = {
  marathonDay: [
    {
      id: "1",
      pathUrl: "923209",
      name: `День 1. Мотивація`,
      subheader: ``,
      text: `Доброго ранку! Що ж - стартуймо! Нині будемо говорити про мотивацію. Що вас мотивує рухатися вперед? Ваші цінності, бажання, винагорода чи тиск ззовні? Знайдіть свою внутрішню мотивацію, яка допоможе вам навчати дітей українською.

Є речі, які багатьом заважають, - це сором за свою, начебто, не таку українську (яка насправді прийнятна), помилки, яких не можна робити (чи можна?), тиск оточення (однак у вас в оточенні завжди знайдуться люди, які вас підтримають!). 

Та найбільшу підтримку ви знайдете тут, адже Ініціатива “Навчай українською” - це команда однодумців, яким не байдуже до майбутнього, вас, дітей та української мови.
`,
      text2: `Існує три переконання, що спиняють людей, які хочуть вивчати українську мову: 
- страх, що не вийде;
- вагання, чи варто витрачати аж так багато зусиль і часу;
- упередження, що українська мова складна й незрозуміла. 

Існує також ідея, що звичку можна виробити за 21 день (а челендж із вивчення української мови триває саме стільки).

Є дуже багато методів і способів вивчати українську, є величезний масив ресурсів, довідкових матеріалів та джерел, які можуть у цьому допомогти.

Сьогодні, 5 листопада о 13.00 відбудеться перший мотиваційний вебінар “Як почати навчати? Перші кроки до української мови викладання” редакторки й перекладачки, викладачки української мови Наталі Вуйтік. Ви дізнаєтеся, як навчитися використовувати свої ресурси та довідкові джерела, щоб вільно й легко почати говорити й писати українською.

Трансляція: <a href="https://youtu.be/JMAF_pSOBws">https://youtu.be/JMAF_pSOBws</a> 

Що потрібно мати із собою на вебінарі? Нічого особливого! Запитання, які вас цікавлять. Тренерка докладе всіх зусиль, щоб на них відповісти.

Що потрібно лишити далеко-далеко перед першим вебінаром? Страх, що українську мову вивчити не вийде, вагання, чи варто це робити взагалі й упередження, що українська — складна і незрозуміла.

Подивіться мотиваційні відео з відкриття першого челенджу “Навчай українською”, який підтримали українські зірки:
<li>Марічка Падалко про українську, як мову можливостей <a href="https://youtu.be/9EVw7GJGkFQ">https://youtu.be/9EVw7GJGkFQ</a>  </li>
<li>Продюсер і шоумен Ігор Кондратюк розповів, чому говорить українською мовою <a href="https://youtu.be/SOS1huHU6bs">https://youtu.be/SOS1huHU6bs</a> </li>
<li>Тенісист Сергій Стаховський розповів, чому він обрав українську мову <a href="https://youtu.be/Is5Dtsb9Pa8">https://youtu.be/Is5Dtsb9Pa8</a>  </li>
<li>Соліст гурту «Антитіла» Тарас Тополя про те, чому мова важлива <a href="https://youtu.be/-fFAcQl-WCM">https://youtu.be/-fFAcQl-WCM</a>   </li>
Для виконання домашнього завдання допоможе вебінар мовної тренерки Ольги Андрусенко про виклики й можливості навчати українською: <a href="https://youtu.be/ZVlEVOdP6YI">https://youtu.be/ZVlEVOdP6YI</a> 
`,
      taskTitle: `Завдання`,
      task: `Напишіть мотиваційний лист собі. Подумайте, що мотивує вас говорити українською найбільше і чому. Напишіть про те, як ви почуваєтеся під час переходу на українську мову викладання. Визначте план, за яким будете рухатися. Спробуйте написати, що можна протиставити страху, ваганням і упередженням. Пропишіть це завдання для себе, ця інформація знадобиться вам упродовж челенджу.`,
    },
    {
      id: "2",
      pathUrl: "042586",
      name: `День 2. Українська музика і хореографія`,
      subheader: ``,
      text: `Українською можна розмовляти, співати, а танцювати?

6 листопада о 14.00 Ірина Бодрик, засновниця і керівниця студії танцю "Несамовиті", на вебінарі “Хореографія українською” поділиться досвідом викладання українською в її студії, розповість про власний досвід створення україномовної студії танцю: як відбувався перехід російськомовних викладачів; з чого почати, приклади та життєві ситуації, які траплялися з дітьми і педагогами.
`,
      text2: `Також спікерка підніме тему хореографічної термінології та анатомії. Порекомендує книги та матеріали, які допоможуть у заняттях, порадить, де знайти цікаві дитячі україномовні пісні, відповість на запитання, які найбільше турбують хореографів.

Трансляція: <a href="https://youtu.be/9C3CVLp-g9s">https://youtu.be/9C3CVLp-g9s</a> 

Декілька років тому завдяки закону про мовні квоти на радіо частка української музики зросла з 5% до понад 50%. Відбувся вибух української музики, тому вибирати точно є з чого! 

Сьогодні знайомимо вас із ресурсами, де можна дізнатися новинки української музики.

Радіо з лише українською музикою: Країна ФМ <a href="https://krainafm.com.ua/">https://krainafm.com.ua/</a> 

Яскраве радіо: <a href="http://yaskraveradio.fm/">http://yaskraveradio.fm/</a>

Вікенд Нової Української Музики на радіо "Промінь": <a style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;" href="http://www.nrcu.gov.ua/prog.html?id=851">http://www.nrcu.gov.ua/prog.html?id=851</a> 

Канал з дитячими піснями: З любов'ю до дітей <a style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 100%;" href="https://www.youtube.com/user/SonechkoProject">https://www.youtube.com/user/SonechkoProject</a>

Bezodnya music - простір для пізнання сучасної української музики без форматів та обмежень: <a style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 85%;" href="https://www.youtube.com/c/bezodnyamusic">https://www.youtube.com/c/bezodnyamusic </a> 

Онлайн-журнал СЛУХ і його ютуб-канал: <a style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;" href="https://www.youtube.com/c/slukh-media/videos">https://www.youtube.com/c/slukh-media/videos</a>  

Muzmapa — онлайн-видання про українську музику. Про себе пишуть: "Ми складаємо музичну мапу України та заповнюємо її білі плями".

Неформат — онлайн-видання про українську якісну некомерційну музику: <a href="https://www.neformat.com.ua/">https://www.neformat.com.ua/</a> 

Слухай українське. Музика від виключно українських виконавців: <a style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;" href="https://www.facebook.com/listenUkrainian/">https://www.facebook.com/listenUkrainian/</a>  
`,
      taskTitle: `Завдання`,
      task: `Поділіться зі своїми колегами ресурсами, де ви слухаєте українську музику, де шукаєте музику для занять. Розкажіть, яка музика саме вам до вподоби - поділіться музичними цікавинками в чаті підтримки.`,
    },
    {
      id: "3",
      pathUrl: "338874",
      name: `День 3. Знайомство`,
      subheader: ``,
      text: `Вітаємо! Сьогодні поговоримо про те, як знайомитися українською. 

Привітання:
Доброго ранку!
Добрий день! 
Доброго здоров’я!
Вітаю!
Привіт!
`,
      text2: `Вибрати є з чого! Відомо, що наймилозвучнішим для людини є звук її імені, тому після привітання назвіть ім'я в кличному відмінку:

Привіт, Оленко! Рада бачити!

Вітаю, Назаре! Приємно познайомитися!

А тоді розкажіть про себе. Наприклад, мене звати Галина, я викладачка вокалу. Спів мене надихає, створює настрій і підбадьорює. Або: Я - Роман, тренер із баскетболу. На наших заняттях ми не лише вчимося бути командою і граємо в баскетбол, а ще й трішки літаємо. 

Починайте розмовляти українською з першого дня знайомства. Таким чином вам буде легше спілкуватися з цією людиною українською й надалі.

<b>Навчальні матеріали:</b>
Подивіться коротке відео про знайомство онлайн-платформи Є-мова: <a href="https://youtu.be/zr7YzTeF57M">https://youtu.be/zr7YzTeF57M</a>

Ви працюєте з дітьми в гуртках та секціях. Як весело і невимушено познайомитися з новими вихованцями? 

Ми вибрали декілька ігор на знайомство, які легко провести українською мовою.

<b>Ігри на знайомство з дітьми:</b>
→Гра на запам’ятовування імен
По колу передається іграшка, наприклад, ведмедик. Перший каже: “Це ведмедик, а мене звати…”. Наступний каже: “Це ведмедик, а мене звати…, я його отримала від....”. Кожен наступний має назвати імена всіх тих, у кого ведмедик був у руках. Наприклад: “Це ведмедик, а мене звати Яринка, я його отримала від Артема, а він його отримав від Назара, а він його отримав від Мілани”. І так до кінця. Діти таким чином запам’ятають один одного.

→Гра на знайомство
Кожна дитина має продовжити речення:
Я є…
Мені весело, коли…
Мені сумно, коли…
Мене звати…
Я хочу…
Я люблю….
Мені цікаво, коли…
Я хочу…
Ніхто не знає про мене, що…

→Гра “Картки”
Кожній дитині дають картку, на якій написано декілька характеристик людини. Наприклад: “Я люблю співати. У мене є собака”. Кожен має знайти таку людину і вписати її ім’я собі в картку.

→“Про мене сказали б…”
Учасники сидять у колі, кожен по черзі говорить фразу, яка розпочинається зі слів: “Мій (хтось) сказав би про мене, що…”. Наприклад, “Мій тато сказав би про мене, що…
`,
      taskTitle: `Завдання`,
      task: `Подумайте, як би  представилися ви? Спробуйте описати себе кількома реченнями. І зробіть це українською! Станьте перед дзеркалом і вголос представтеся. Назвіть своє ім’я, розкажіть, звідки ви, чим займаєтеся, що любите робити, які у вас досягнення.`,
    },
    {
      id: "4",
      pathUrl: "357275",
      name: `День 4. Помилки`,
      subheader: ``,
      text: `Вітаємо! Ні, ми не будемо нікого сварити за помилки! Бо помилятися - не страшно. Головне - пробувати спілкуватися. Щоб вивчити будь-яку мову, нею потрібно почати розмовляти. І робити помилки, особливо, на початку, - це НОРМАЛЬНО. Людина не може одразу говорити ідеально  якоюсь мовою. `,
      text2: `Сьогодні об 11.00 разом із психотерапевткою, кандидаткою психологічних наук, науковою співробітницею Інституту соціальної та політичної психології Ольгою Кухарук на вебінарі «Психологічна підтримка зміни мови» поговоримо про таке:

<li>чому зміна мови  буває непростою - які психологічні труднощі доводиться долати, змінюючи мову;</li>
<li>як відповідати на незручні питання оточення про зміну мови;</li>
<li>як можна зробити зміну мови більш комфортною.</li>

Під час трансляції ви зможете поставити запитання й отримати відповіді

Трансляція: <a href="https://youtu.be/YbYaMylxM8c">https://youtu.be/YbYaMylxM8c</a> 

<b>Навчальні матеріали:</b>

Дуже часто в категорію "неправильної" мови потрапляють навіть питомо українські слова, котрі просто схожі на російські. Лекція письменника і дослідника Остапа Українця про суржик: <a href="https://youtu.be/AiiWg9_XRTo">https://youtu.be/AiiWg9_XRTo</a> 

Для гурманів лекція Лариси Масенко, мовознавиці, доктора філологічних наук, професорки Національного університету «Києво-Могилянська академія», про історію мови і проблеми з її вживанням, які виникали внаслідок цього: <a href="https://youtu.be/VxKBt6LbjJg">https://youtu.be/VxKBt6LbjJg</a>                                                                                                                                                

Для тих, хто робить перші кроки в українській мові 22 уроки до мовної впевненості (рівень В1) від Є-мова (безкоштовний курс): <a href="https://bit.ly/35hhels">https://bit.ly/35hhels</a> 

Лепетун допоможе вам вивчити українську мову швидко і весело <a href="https://ukr-mova.in.ua">ukr-mova.in.ua</a>  

<b>АНОНС:</b>

9 листопада, у День української писемності та мови, запрошуємо вас о 12.00 на онлайн-зустріч «Українська. Історії зірок», на якій зіркові учасники у прямому ефірі поділяться своїм досвідом переходу на українську та спілкування українською.

Трансляція: <a href="https://youtu.be/rohS9RnYrxQ">https://youtu.be/rohS9RnYrxQ</a>
 
Учасники зустрічі:
<li/>Олімпійський чемпіон Ігор XXIX Олімпіади (2008) з кульової стрільби з пістолета Олександр Петрів,
<li/>чемпіон Параолімпійських Ігор у Ріо-де-Жанейро Андрій Демчук,
<li/>кулінарний експерт, телеведучий Євген Клопотенко,
<li/>актор, засновник “Мамахохотала”, продюсер фільмів “Інфоголік”, “Бурштинові копи” Євген Янович,
<li/>телеведуча програми “Факти” на телеканалі  ICTV, Оксана Гутцайт.`,
      taskTitle: `Завдання`,
      task: `Не помиляється той, хто нічого не робить! Отже, завдання на сьогодні: вам потрібно заговорити українською мовою в будь-якій побутовій ситуації. Наприклад, у супермаркеті до касира (“Добрий вечір! Ні, дякую, пакет не потрібен, картка є”) або в маршрутці (“Передайте за проїзд! На зупинці, будь ласка!”) тощо.

Ні, це не потрібно знімати не відео. Напишіть нам, що ви відчули, чи вам було складно. Що не викликало труднощів? Похваліть себе в будь-якому разі!
`,
    },
    {
      id: "5",
      pathUrl: "059513",
      name: `День 5. День української мови та писемності`,
      subheader: ``,
      text: `Друзі, вітаємо зі святом!

Щороку 9 листопада в Україні відзначають День української писемності та мови. Офіційним святом цей день став у 1997 році. Дата 9 листопада припадає і на вшанування пам’яті Нестора Літописця, адже, на думку вчених, саме з його праць починається писемна українська мова.
`,
      text2: `Українська мова належить до слов’янської групи індоєвропейської мовної сім’ї. Кількість носіїв – понад 45 мільйонів, більшість із яких живе в Україні. Поширена також у Білорусі, Молдові, Польщі, Росії, Румунії, Словаччині, США та інших країнах, де мешкають українці. 

А письмо стало одним із найбільших винаходів людства - писемні знаки дали людям можливість зберегти знання про світ та природу. 

Сьогодні о 12.00 запрошуємо вас на онлайн-зустріч «Українська. Історії зірок», на якій зіркові учасники у прямому ефірі поділяться своїм досвідом переходу на українську та спілкування українською.

Трансляція: <a href="https://youtu.be/rohS9RnYrxQ">https://youtu.be/rohS9RnYrxQ</a>    

Учасники зустрічі:
<li/>Олімпійський чемпіон Ігор XXIX Олімпіади (2008) з кульової стрільби з пістолета Олександр Петрів, 
<li/>чемпіон Параолімпійських Ігор у Ріо-де-Жанейро Андрій Демчук,  
<li/>кулінарний експерт, телеведучий Євген Клопотенко,  
<li/>актор, засновник “Мамахохотала”, продюсер фільмів “Інфоголік”, “Бурштинові копи” Євген Янович,
<li/>телеведуча програми “Факти” на телеканалі  ICTV, Оксана Гутцайт.

<b>Навчальні матеріали:</b>

Відео Ukraïner про історію української мови: <a href="https://youtu.be/nqReOxAjuWg">https://youtu.be/nqReOxAjuWg </a>

Цікаві факти про українську мову:

Найбільше перекладений літературний твір – «Заповіт» Тараса Шевченка. Його переклали на 147 мов народів світу.

Особливістю української мови є те, що вона багата на зменшувальні форми. 

Найбільш уживаною літерою в українській абетці є літера «п»; на неї також починається найбільша кількість слів. Літера «ф» – найменш уживана.

Назви всіх дитинчат тварин в українській мові належать до середнього роду.

Сучасна українська мова нараховує приблизно 256 тисяч слів і є у списку мов, які успішно розвиваються.

Найстарішою українською піснею, запис якої зберігся до наших днів, вважається пісня «Дунаю, Дунаю, чому смутен течеш?»
`,
      taskTitle: `Завдання`,
      task: `Розкажіть учням та знайомим про те, що берете участь у челенджі. Що надихнуло вас на цей крок. Напишіть у соціальних мережах коротенький пост про те, чому варто переходити на українську. Знайдіть 3-5 цікавих фактів про українську мову й поділіться знахідками в чаті підтримки.`,
    },
      {
          id: "6",
          pathUrl: "023569",
          name: `День 6. Запам'ятовуємо українською`,
          subheader: ``,
          text: `Доброго ранку! 

Сьогодні говоритимемо про пам\`ять.
`,
          text2: `Люди часто скаржаться на те, що мають погану пам\`ять, що ніколи не можуть повторити  правил і забувають про винятки. А з наголосами - узагалі катастрофа.

Проте є кілька секретів, які допоможуть усе владнати. Перший секрет у тому, що пам’ять у нас - чудова, просто ми не вміємо правильно запам’ятовувати.

А другий секрет - зубрити не можна, треба уявляти.

Сьогодні тренерка з ейдетики та мнемотехніки Мар’яна Лелик розповість про типи пам’яті, асоціативні ланцюжки, про те, куди записується інформація, яку ми вивчаємо, наскільки довго ми можемо пам’ятати ту чи іншу інформацію, про прийоми, які спрощують запам’ятовування й полегшують пригадування.

Дивіться вебінар “Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка” 10 листопада о 10.00. 

Трансляція: <a href="https://youtu.be/o_2rAf5M1w0">https://youtu.be/o_2rAf5M1w0</a> 

Можна буде поставити питання у коментарях. Запис вебінару буде за тим самим посиланням.

<b>Навчальні матеріали</b>

Яка різниця між стосунками та відносинами, як вимовляється звук "ч", чому правильно казати “руку підносимо”, “підручники розгортаємо”, “думки збігаються” - про це та багато іншого корисного дізнаєтесь із лекцій вчительки української мови і літератури Оксани Гордійчук на каналі освітньої платформи Є-мова:

<li/>Фонетичний рівень, або Як звучить українська мова? <a href="https://youtu.be/a_msFb2VWYk">https://youtu.be/a_msFb2VWYk</a>
<li/>Лексичний рівень, або Як дібрати влучне слово? <a href="https://youtu.be/fUrF18h_5rY">https://youtu.be/fUrF18h_5rY</a>

Спробуйте запам‘ятати новий матеріал, використовуючи один чи кілька методів ейдетики.
`,
          taskTitle: `Завдання`,
          task: `Виберіть одне з правил української мови й спробуйте його пояснити по-новому, використовуючи образне мислення й асоціації. Вигадайте цікаві нестандартні приклади, якими можна це правило проілюструвати. Спробуйте скласти свій список покупок, де не буде росіянізмів і кальок. Наприклад: масло - олія, ветчина - шинка, творог - сир м’який, свекла - буряк і т.д.`,
      },
      {
          id: "7",
          pathUrl: "128569",
          name: `День 7. Підтримка`,
          subheader: ``,
          text: `Доброго ранку! 

Сьогодні минає третина челенджу. Ви вже обміркували свою мотивацію, знаєте, як запам'ятовувати те, що раніше здавалося складним і ще багато-багато іншого. І ви вже готові розмовляти українською. Та попри досвід, бажання й можливості, нам потрібно ще щось. Щось, що надихає й мотивує рухатися далі, не зупинятися, не зневірюватися. Це - підтримка.
`,
          text2: `Підтримка оточення - це дуже важливий компонент під час переходу на українську мову.

Український студент, блогер Данило Гайдамаха (він же "Чорнобривий"), який зібрав у соцмережі TikTok аудиторію в понад 110 тис. осіб, розповів, як у 16 років перейшов на українську (цитата із сайту Docudays UA):

"Для мене це було найважче. Я кілька разів пояснював своїй родині, що переходжу на українську, і власне говорив у родині рідною мовою, але мої слова просто не клеїлися. І це було дивовижно, тому що з друзями я абсолютно вільно говорив українською, а тут якийсь психологічний блок, і ти не можеш його переступити. І тоді я зрозумів: нехай все лишається, як є. Та згодом подумав, що це не лише стосується мого комфорту, але й впливає на мого молодшого брата. Тому що мовне питання формується у родині. Від, того як ви вирішите мовне питання, якою мовою говоритимете, залежить, що ви залишите своїм дітям. Зрештою, я попросив батьків спілкуватися так, як їм зручно, але підтримував мого брата у прагненні говорити українською. У результаті батьки, які все життя говорили російською, себе теж пересилили — і перейшли на рідну мову. Уявіть собі: вихідці з Криму, які у понад 40-літньому віці змогли перейти на українську мову".

Сьогодні об 11.00 викладачі Львівського державного університету фізичної культури імені Івана Боберського Богдан Виноградський – доктор наук, професор, майстер спорту та Оксана Борис – керівниця редакційно-видавничого відділу, колишня лекторка української мови Університету Етвеша Лоранда (Будапешт, Угорщина) запрошують на <b>вебінар про українську спортивну термінологію "До Олімпу через українське слово".</b>

Трансляція: <a href="https://youtu.be/5-w8dD-JXgk">https://youtu.be/5-w8dD-JXgk</a>   

Поговоримо про:
1. Типи спортивної термінології (наукова, практична, сленг).
2. Словники спортивних термінів: стан і перспективи. 
3. Мовні казуси використання термінів у різних видах спорту.

Ініціатива “Навчай українською” радить заручитися підтримкою колег. 

Ми також зібрали досвід викладачів, які перейшли на українську. Вони радять розповісти своїм учням і колегам, що ви берете участь у челенджі, і попросити підтримки. Діти вам допоможуть, коли ви під час заняття раптом забудете якесь слово. Колеги можуть допомогти шукати методичні матеріали українською.

Перегляньте один із вебінарів, які були записані для челенджу “Навчай українською” та освітньої платформи “Є-мова”:

<li/>Вебінар викладача акторської майстерності Олександра Жугана "Як перейти на українську мову викладання" - про історію переходу на українську і труднощі з цим пов’язані, про досвід та помилки в українізації оточення, як і про що говорити з дітьми та їхніми батьками, коли піднімається питання мови: <a href="https://youtu.be/rIb2skc1tv4">https://youtu.be/rIb2skc1tv4</a>
<li/>Лекція Олени Подопригори, заступниці директора з виховної роботи Бахмутського НВК № 11 Донецької області про перехід школи на українську мову викладання, заперечення батьків та правильну комунікацію: <a href="https://youtu.be/e2Xthfcwj98">https://youtu.be/e2Xthfcwj98</a>
`,
          taskTitle: `Завдання`,
          task: `З'ясуйте, чия підтримка для вас є дуже важливою. Спілкуйтеся частіше з тією людиною, розповідайте їй про свої успіхи та невдачі, про те, як почуваєтеся. Спробуйте поспілкуватися з однодумцями в чаті підтримки челенджу. Можливо, ви станете для когось підтримкою. Сформуйте власний словничок термінології, яку використовуєте на заняттях, поділіться ним із учнями й батьками.`,
      },
      {
          id: "8",
          pathUrl: "023345",
          name: `День 8. Мовлення`,
          subheader: ``,
          text: `Чим більше ми практикуємо українську, тим краще у нас це виходить. 

Наш голос і те, що і як ми говоримо, - це наша безпосередня проєкція на навколишній світ. Звук нашого голосу, чіткість мовлення, уміння стисло і влучно формулювати думки, а також те, як ми сприймаємо свій звуковий образ, формує нашу самооцінку і впливає на загальну якість життя. 
`,
          text2: `12 листопада об 11.00 на вебінарі “Голос і мовлення” поговоримо про те, звідки береться голос, чому дехто не любить звук власного голосу, чи можна розвивати свій голос і навички мовлення (спойлер: так) і як це робити. Опановуємо сім кроків до вільного гарного голосу, чіткого зв’язного мовлення і харизматичної впевненої подачі. Вчимося працювати з текстом та пробуємо імпровізувати українською.

Спікер: Олександр Жуган - викладач акторської та сценічної майстерності, психолог.

Трансляція: <a href="https://youtu.be/-QRBoDNdRp8">https://youtu.be/-QRBoDNdRp8</a> 
`,
          taskTitle: `Завдання`,
          task: `Спробуйте вивчити кілька скоромовок, які вам найбільше подобаються, і розказуйте їх перед дзеркалом. Вивчіть скоромовки з дітьми. Запропонуйте дітям кілька вправ, про які дізналися сьогодні на вебінарі. Напишіть у чаті, яка вправа сподобалася вам найбільше.`,
      },
      {
          id: "9",
          pathUrl: "245789",
          name: `День 9. Українські фільми для дітей і дорослих`,
          subheader: ``,
          text: `Доброго ранку! 

На цих вихідних допоможемо вам приємно і з користю провести кілька годин у компанії дітей і якісного українського кіно. Наша добірка – чудові українські стрічки для дітей і дорослих, де кожен знайде щось для себе. 
`,
          text2: `Бобот та енергія Всесвіту (2018)
На всій планеті зникла електрика, прилетіли інопланетяни, готуються захопити Землю і врятувати її може тільки 12-річний Вадик разом зі своїм новим другом – інопланетянином у вигляді трансформаторної будки. Хочете знати таємну силу трансформаторних будок – дивіться.

11 дітей з Моршина (2019)
Легка і кумедна історія про те, як 11 моршинських дітей не дають одній жадібній пані заробити легких грошей на українських надрах.

Тільки диво (2019)
Якби Джон Сноу жив в українських горах – з ним би могла трапитись ця історія. Треба рятувати рідне містечко, рушити на край світу, битись з невідомими потворами, стихійними лихами, нескінченною зимою. Драконів нема, але є дикі вовки, білі пухнасті собаки, а ще кохання і дружба.

Микита Кожум'яка (2016)
Чудовий мультик про пригоди Микити – сина Кирила Кожум’яки. Хлопчику треба і Змія перемогти, і світ врятувати - і все це з чудовим гумором. Згадайте, що таке справжня дружба і в чому сила маленьких героїв. І дітям покажіть.

Сторожова застава (2017)
Якщо ви ще бачили цей блокбастер про міжчасовий портал у Карпатах – бігом дивіться. Підліток Віктор опиняється в Київській Русі ХІІ століття. А там князь, дружина, половці наступають, артефакти зникають і взагалі – людство під загрозою, а смартфон зарядити нема де.

Фокстер і Макс (2019)
Макс неочікувано для себе створює пса-наноробота, коли псує комунальну власність. Міг би весело і щасливо жити з твариною, яку не треба годувати, але.. По-перше, у собаки суперможливості, а по-друге чимало недобрих дорослих теж собі такого хочуть.

Пригоди S Миколая (2018)
Ми всі колись хотіли зустріти справжнього Миколая і розповісти йому все, що наболіло. У Артемка це вийшло. Правда Миколай був не Миколаєм, а злодієм і все не так однозначно. Тому хлопчині з сестрою довелось шукати справжнього чарівника і відновлювати світову справедливість.

Мої думки тихі (2019)
Фільм, після якого вам захочеться подзвонити мамі, а ваші діти підуть гуглити про «крякання рахівського крижня». Неймовірно тепла сімейна комедія, де в героях ви знайдете і себе, і друзів, і родичів.

Поліна і таємниця кіностудії (2019)
Життя не балувало маленьку Поліну – батьків нема, родичі хочуть її позбутись через спадок. Але спадок Поліна знаходить раніше і поринає у чарівний світ боротьби добра зі злом у світі кінофільмів. Бонусом дізнається цікаві факти про свою справжню родину.

Наші котики (2020)
Про війну можна з гумором і по-доброму. Дегуманізація ворога, нетривіальні жарти і тонкі алюзії до не завжди смішних реалій. Зі страшного – образ смерті, але підліткам фільм сподобається. Дорослі взагалі будуть у захваті

Пекельна Хоругва, або Різдво Козацьке (2019)
Легка казка, смачно приправлена українським гумором. Козаки, чорти, химери й інші представники української міфології. Ідеально для сімейного вечора з дітьми.

Ці фільми ви можете знайти в інтернеті.
`,
          taskTitle: `Завдання`,
          task: `Поділіться у соцмережах або у чаті підтримки враженнями, які з українських фільмів останніх років вам сподобалися.`,
      },
      {
          id: "10",
          pathUrl: "836901",
          name: `День 10. Ігри українською`,
          subheader: ``,
          text: `Доброго ранку!

Пам'ятаєте ігри, які ми пропонували вам для знайомства? Чи вподобали ваші вихованці собі якусь із них?

Сьогодні пропонуємо вам ще більше - ми зібрали ігри для дітей і підлітків (та й для дорослих вони теж підійдуть), пов’язані з мовою. 

У навчальних матеріалах ви отримаєте лінки на ресурси з різноманітними іграми для дітей і підлітків.
`,
          text2: `<b>"Слово"</b>
Учасник гри виходить за двері. Решта загадують слово (грайте українською мовою!) й об’єднуються у таку кількість команд, скільки складів у даному слові. Коли заходить учасник гри, команди в один голос кричать кожен свій склад. Потрібно вгадати задумане слово.

<b>"Алфавіт навколо нас"</b>
Ведучий пропонує 2 командам написати за абеткою протягом 5-10 хвилин назви навколишніх предметів і речей, що знаходяться в кімнаті чи приміщенні. Можна писати по кілька предметів на одну букву. Головні труднощі – відшукати предмети на всі букви алфавіту. Переможе той, хто складе найдовший список слів. До того ж діти повинні обов'язково показати, де знаходиться названий предмет.

<b>"МСП"</b>
Учаснику потрібно вгадати, що таке "МСП". "МСП" - це абревіатура фрази "мій сусід праворуч". Один учасник гри ставить всім решта по черзі за годинниковою стрілкою питання, на які можна відповісти "так" або "ні". Гравці зобов'язані відповідати правду, але відповідають тільки про свого сусіда праворуч.

<b>"Пів слова за вами"</b>
Учасники гри сідають у коло і перекидають один одному м’яч. При цьому той, хто кидає, голосно говорить половину якого-небудь слова, а той, хто ловить, повинен назвати другу половину. Наприклад, теле-фон, чіп-си. Кидати м’яч можна будь-якому гравцю. Відповідати потрібно швидко. За кожну помилку чи затримку гравець вибуває з гри. Умови гри можна ускладнити. Ведучий дає (говорить) половину слова кожному, хто сидить у колі. І потрібно продовжити другу половину. Не може – штрафний бал. Ведучий починає говорити свою половину слова, наприклад: “теле…”, а діти продовжують: телефон, телескоп, телевізор і т.д.

<b>"Газетна замітка"</b>
Із заголовків різних старих газет і журналів (їх перед тим необхідно вирізати), діти повинні скласти коротку гумористичну розповідь, оголошення чи рекламу. Для цього потрібні папір, клей, пензлик і почуття гумору.

Дякуємо за ігри ВМГО “Спілка української молоді в Україні”.

<b>Навчальні матеріали:</b>
<li/>72 гри, у які грають пластуни (і не лише): <a href="https://plastovabanka.org.ua/igry/" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://plastovabanka.org.ua/igry/</a>
<li/>Місце, де дуже багато ігор для дітей, юнацтва та молоді: <a href="https://www.plast.org.ua/repository-activities/" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://www.plast.org.ua/repository-activities/</a>
<li/>У розділі Виховні матеріали – Методика ви можете ознайомитись з іграми, у які грають в Спілці української молоді: <a href="https://cym.org/materials/">https://cym.org/materials/</a>
<li/>55 ігор, які можна грати в приміщенні: <a href="https://pedpresa.com.ua/201548-55-energijnyh-igor-vdoma.html" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://pedpresa.com.ua/201548-55-energijnyh-igor-vdoma.html</a>
`,
          taskTitle: `Завдання`,
          task: `Спробуйте цього тижня пограти зі своїми вихованцями в якісь із запропонованих ігор українською. Яка гра сподобалася найбільше? Чи допомагає такий ігровий формат вам у проведенні занять? Напишіть нам ваші враження.`,
      },
      {
          id: "11",
          pathUrl: "783269",
          name: `День 11. Мовна практика`,
          subheader: ``,
          text: `Доброго ранку! Цього тижня розпочинаються практикуми з української мови. Допомагатимуть учасникам практикумів наші спеціалісти.

Ми зібрали для вас матеріали з практичними порадами, які ви можете застосувати у своєму переході на українську мову викладання.
`,
          text2: `<b>Навчальні матеріали:</b>

Із цих відео освітньої платформи Є-мова ви дізнаєтесь, що в українській мові слова біль, нежить, тюль, ступінь, дріб, степ та літопис належать до чоловічого роду, а слово путь до жіночого. І не лише про це у лекціях вчительки української мови і літератури Оксани Гордійчук:

<li/>Морфологія та синтаксис, або Як приборкати дракона граматики? (Частина 1) <a href="https://youtu.be/QLHFk8zyN4U">https://youtu.be/QLHFk8zyN4U</a>
<li/>Морфологія та синтаксис, або Як приборкати дракона граматики? (Частина 2) <a href="https://youtu.be/O80ejaPbw0U">https://youtu.be/O80ejaPbw0U</a>

4 кроки до викладання українською. Своїм досвідом поділилась тренерка Ліна Кадом, яка почала проводити свої тренінги українською мовою: <a href="https://bit.ly/2HcBMCr">https://bit.ly/2HcBMCr</a>

“Першими моїми співрозмовниками стали продавчині (шопінг), адміністратори готелів (подорожі Україною) та пересічні перехожі (запитати дорогу). Заздалегідь обмірковувала діалоги українською мовою. Нібито все гаразд – мене розуміють, співрозмовники не насміхаються, а допомагають та підтримують, – і я стала почуватися більш впевненою та вмотивованою рухатися далі”.
`,
          taskTitle: `Завдання`,
          task: `Подумайте, як саме вам легше практикувати українську: розмовляти на побутові теми; ділитися досвідом у вузькому колі спеціалістів чи вчити напам'ять тексти українською (вірші, пісні)? Поділіться знахідками в групі підтримки.
<br><br>Розгорніть словник на будь-якій сторінці, виберіть кілька слів. Спробуйте пояснити українською, в якому контексті їх найчастіше вживають.
`,
      },
      {
          id: "12",
          pathUrl: "789250",
          name: `День 12. Мовна стійкість`,
          subheader: ``,
          text: `Для того, щоб упевнено себе почувати у викладанні українською мовою, вам потрібно мати підтримку і середовище, де можна практикувати українську. Спільнота, яка підтримає і стане джерелом наснаги, допоможе у мовній стійкості. Рекомендуємо знайти середовище, яке спілкується українською, додатись у такі групи в соціальних мережах та сформувати дружнє до української мови коло спілкування.`,
          text2: `Зверніть увагу, чи є українська мова на панелі приладів у вашому авто, ноутбуці, смартфоні, пральній машині, мультиварці, інструкції до ліків. Часто достатньо лише звернути увагу на це у продавця і він надасть вам ноутбук із ліцензійною операційною системою, де уже встановлена українська мова.
          
          <b>Навчальні матеріали:</b>
          
          <li/>Для натхнення - історії людей, які перейшли на українську на каналі “Моя мовна стійкість” <a href="https://bit.ly/3ppqITB">https://bit.ly/3ppqITB</a>
<li/>Сторінка проєкту  "Переходь на українську", яка відкриє вам історії людей, які перейшли на українську мову й відтоді спілкуються нею постійно: <a href="https://www.facebook.com/perehodnamovu" style=" -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden; display: -webkit-box; width: 60%;">https://www.facebook.com/perehodnamovu</a>
<li/>Поради та лайфгаки під час переходу на українську мову викладання від Олександра Медведєва, співзасновника і керівника Студії комп’ютерної графіки та дизайну «Імпресія»: <a href="https://bit.ly/2GRcvgV">https://bit.ly/2GRcvgV</a>
          `,
          taskTitle: `Завдання`,
          task: `Перейдіть на українську мову у вайбер-чатах та інших каналах зв’язку з батьками ваших вихованців. Напишіть свої результати за час челенджу. У чому ви стали кращими? Що вже отримали? Що для вас означає “мовна стійкість”? Чи важливою вона є в переході на іншу мову?`,
      },
      {
          id: "13",
          pathUrl: "549326",
          name: `День 13. Працюємо з дітьми`,
          subheader: ``,
          text: `Нині будемо говорити про те, як навчати дошкільнят українською, де шукати ігри, вправи та пісні для дітей.`,
          text2: `<b>Навчальні матеріали:</b>

Дивіться запис вебінару “Навчання українською: майстерня дитячого розвитку” про заняття з дошкільнятам та про те, як додати мовний бар'єр. Розповідає Марія Кирик, дитяча психологиня та авторка Youtube-каналу про психологію, розвиток і батьківство. До Києва вона переїхала із Горлівки: <a href="https://youtu.be/KMxCQlAYt_Y">https://youtu.be/KMxCQlAYt_Y</a>

"Дитячий психолог" - сайт для батьків та дитячих психологів. Тут зібрані розвивальні ігри та заняття для дітей, поради психологів: <a href="https://dytpsyholog.com/">https://dytpsyholog.com/</a>

Проєкт підтримки дитячого читання «BaraBooka. Простір української дитячої книги» – перший український універсальний інформаційний ресурс про видання для дітей та юнацтва: <a href="http://www.barabooka.com.ua/">http://www.barabooka.com.ua/</a>
`,
          taskTitle: `Завдання`,
          task: `Залучіть своїх учнів під час занять до перекладу слів з російської на українську мову. Також можна давати їм домашнє завдання - знайти на наступне заняття терміни українською мовою.`,
      },
      {
          id: "14",
          pathUrl: "549291",
          name: `День 14. Подолання мовного бар'єру`,
          subheader: ``,
          text: `Ми з вами вже мотивовані й підбадьорені позитивним досвідом викладання українською. З'ясували, що саме заважає, а що може допомогти. Тепер важливо здолати мовний бар’єр. Ми зібрали кілька корисних ланок, які ви зможете переглянути й отримати потрібну інформацію.`,
          text2: `<b>Навчальні матеріали:</b>

<li/>Лайфхак українською <a href="http://bit.ly/381LNdE">http://bit.ly/381LNdE</a>
Це програма на Суспільному, з якої ви можете дізнатися, як замінити іншомовні слова українськими, де наголос у слові "лате", як прощатися красиво, українську лайку, про ступені порівняння прикметників та ін.

<li/>Пропонуємо послухати запис вебінару “Процес переходу на іншу мову: поради і таємниці”: <a href="https://youtu.be/FXACB6bukmg">https://youtu.be/FXACB6bukmg</a>, у якому вчителька англійської для дітей і тренерка Олена Жупанова розповість про те, як комфортно перейти на іншу мову викладання, як діти сприймають другу мову і як долучаються до неї, чи потрібно виправляти помилки і як це краще робити, як досягти психологічного комфорту батьків і дітей.
`,
          taskTitle: `Завдання`,
          task: `Спробуйте повправлятися у творенні ступенів порівняння прикметників: добре - краще - найкраще, погано - гірше - найгірше, сильно - сильніше - найсильніше тощо. Напишіть у чаті три прикметники, які вживаєте на своїх заняттях найчастіше. `,
      },
      {
          id: "15",
          pathUrl: "549292",
          name: `День 15. Створення середовища та спільноти`,
          subheader: ``,
          text: `Створити комфортне й затишне середовище, де всі будуть почуватися добре, - непросте завдання, проте надзвичайно важливе. Адже саме в цьому середовищі ви будете викладати, творити, працювати, відпочивати. Це складний процес, який поєднує в собі купу різноманітних методів та підходів і тільки комплексне їхнє використання дозволить і учням, і викладачам досягти очікуваного результату.

Саме тому в пріоритеті кожного керівника має бути питання створення особливого середовища, спільноти, яка не лише представлятиме той чи інший напрям, а й транслювати ціннісні засади, на яких сформувалася і які є орієнтиром для всіх учасників цієї спільноти. Серед таких орієнтирів є і мова.

Сьогодні об 11.00 на вебінарі «Як перейти на українську всім колективом?» команда Дитячо-юнацького центру “Штурм” (м. Дніпро) - найбільшого закладу позашкільної освіти в Україні, переможця Всеукраїнського та міжнародного проєкту “Дистанційна робота. Заклади позашкілля”, переможця проєкту “Найкращі практики молодіжної роботи” - поділиться своїм досвідом переходу на українську мову, цікавими ситуаціями і нестандартними рішеннями.

Трансляція: <a href="https://youtu.be/RLrmhYv-GSA">https://youtu.be/RLrmhYv-GSA</a>
`,
          text2: `<b>Навчальні матеріали:</b>
<li>Вебінар, записаний для челенджу “Навчай українською” та освітньої платформи “Є-мова” викладача акторської майстерності Олександра Жугана "Як перейти на українську мову викладання", - про історію переходу на українську і труднощі з цим пов’язані, про досвід та помилки в українізації оточення, як і про що говорити з дітьми та їхніми батьками, коли піднімається питання мови: <a href="https://youtu.be/rIb2skc1tv4">https://youtu.be/rIb2skc1tv4</a>  
`,
          taskTitle: `Завдання`,
          task: `З'ясуйте, у якому середовищі і чия підтримка для вас є дуже важливою. Спілкуйтеся частіше з тими людьми, розповідайте їм про свої успіхи та невдачі, про те, як почуваєтеся. Спробуйте поспілкуватися з однодумцями в чаті підтримки челенджу. Можливо, ви станете для когось підтримкою. Чи можете ви назвати наш чат спільнотою? Чи комфортно вам тут і чому?`,
      },
      {
          id: "16",
          pathUrl: "549293",
          name: `День 16. Український ютуб`,
          subheader: ``,
          text: `Сьогодні відпочиньте в українському культурному полі й ознайомтеся з українським ютубом. Ми підшукали канали, які відкриють вам світ науки, музики, кіно, подорожей та всякого цікавого.`,
          text2: `
          <b>Канали, які розповідають про науку популярно:</b>
          
            <li>Клятий Раціоналіст <a href="https://bit.ly/3lHCINS">https://bit.ly/3lHCINS</a>
            Науково-популярний контент, що пропагує раціоналізм. Презумпція наукового методу.</li>
            <li>Цікава наука <a href="https://bit.ly/3ptUDu0">https://bit.ly/3ptUDu0</a>
            Тут ви знайдете перекладені українською науково-популярні та освітні ролики на різні наукові теми з фізики, астрономії, біології, географії та математики.</li>
            <li>Tokar.ua <a href="https://bit.ly/37fmBjx">https://bit.ly/37fmBjx</a>
            Простими словами про складне - безпеку, технології та винаходи. Цікаво і доступно.</li>
            <li>Довколаботаніка <a href="https://bit.ly/2UrIhYI">https://bit.ly/2UrIhYI</a>
            Несподівані факти зі світу ботаніки, які вас вразять.</li>
            
          <b>Гуманітарні науки:</b>
          
            <li>Твоя підпільна гуманітарка <a href="https://bit.ly/3lxb9qv">https://bit.ly/3lxb9qv</a>
            Лекції, інтерв’ю та онлайн-дискусії, що в той чи інший спосіб стосуються гуманітарних наук.</li>
            <li>Імені Т.Г. Шевченка <a href="https://bit.ly/2UnsINW">https://bit.ly/2UnsINW</a>
            Тут ви знайдете відео від братів Капранових, де вони розказують популярно про історію України.</li>
            <li>Історія без міфів <a href="https://bit.ly/35yXEBr">https://bit.ly/35yXEBr</a>
            Світова історія та історія України без прикрас та фальсифікацій.</li>
            <li>Wild Fox <a href="https://bit.ly/2Gd18M9">https://bit.ly/2Gd18M9</a>
            Це популяризація знань з історії та культури України. Знатимете, що готували в часи Київської Русі і яким бізнесом займались.</li>
            
          <b>Для дітей:</b>
          
            <li>Майстерня дитячого розвитку <a href="https://bit.ly/3kvg2zc">https://bit.ly/3kvg2zc</a>
            Канал про психологію, розвиток та батьківство. Тут можна знайти розвиваючі ігри та різноманітні вправи, які пригодяться у роботі з дітьми.</li>
            <li>Книга-мандрівка. Україна <a href="https://bit.ly/2unA4qY">https://bit.ly/2unA4qY</a>
            Мультсеріал, заснований на реальних подіях, описаних в ілюстрованому виданні «Книга-мандрівка. Україна».</li>
          
          <b>Про подорожі:</b>
          
            <li>Ukraїner <a href="https://bit.ly/2sIsIOf">https://bit.ly/2sIsIOf</a>
            Експедиція Україною, подорожі, активний туризм, відкриття, мандри, антропологія та етнографія. Неймовірної краси відео з висоти пташиного польоту!</li>
            <li>ПроПоходи <a href="https://bit.ly/2tJxknU">https://bit.ly/2tJxknU</a>
            Про мандрівки в гори з сучасним спорядженням та враження від останніх досягнень в світі полегшеного туристичного екіпірування.</li>
            <li>Двоколісні хроніки <a href="https://bit.ly/2IzvNrX">https://bit.ly/2IzvNrX</a>
            Музиканти гурту “Фолькнери” Володимир Муляр, Ярина Квітка та їхній малюк Марко, який народився у мандрівці, їздять по світу на велосипедах і показують світ таким, яким вони його бачать. Кілька років тому доїхали на велосипедах з України до Австралії.</li>

          <b>Йога:</b>
          
            <li>Yoga Dopomoga <a href="https://bit.ly/35vIFry">https://bit.ly/35vIFry</a>
            Все про йогу українською мовою.</li>
            
          <b>Про кіно:</b>
          
            <li>Загін Кіноманів <a href="https://bit.ly/2IDl6EF">https://bit.ly/2IDl6EF</a>
            Тут рецензії на українські та іноземні фільми, а також цікаві репортажі з фестивалів і прем'єр.</li>
            <li>Geek Journal <a href="https://www.youtube.com/c/GeekJournal">https://www.youtube.com/c/GeekJournal</a>
            Тут можна знайти огляди українських та іноземних фільмів, актуальні новини зі всього світу та ще багато цікавого.</li>
            <li>Корейці на човні <a href="https://bit.ly/37iQKha">https://bit.ly/37iQKha</a>
            Корейці знімають відео про Україну і говорять українською!</li>
            
          <b>Про музику:</b>
          
            <li>З любов’ю до дітей - Дитячі пісні <a href="https://www.youtube.com/user/SonechkoProject">https://www.youtube.com/user/SonechkoProject</a>
            Тут ви знайдете веселі дитячі пісні, цікаві абетки, руханки, розвивальні відео та мультики для дітей українською мовою.</li>
            <li>Polyphonyproject.com <a href="https://bit.ly/36fIiP1">https://bit.ly/36fIiP1</a>
            Профейсійні багатоголосі записи українських народних автентичних пісень.</li>
            Найповніший список Youtube-каналів українською мовою є тут: <a href="https://manifest.in.ua/rt/">https://manifest.in.ua/rt/</a>          
`,
          taskTitle: `Завдання`,
          task: `Подивіться три відео на ютубі українською на різну тематику. Зверніть увагу, які відео вам почав пропонувати ютуб після цього. Якщо бажаєте, поділіться у соцмережах українськими ютуб-каналами, які вам сподобалися.`,
      },
      {
          id: "17",
          pathUrl: "549294",
          name: `День 17. Працюємо з дітьми`,
          subheader: ``,
          text: `Нині будемо говорити про те, як навчати дошкільнят українською. Для вас це важче чи легше, ніж працювати зі старшими дітьми? Як ви гадаєте, ваш перехід на українську мову викладання вплине на те, якою мовою діти спілкуватимуться між собою чи вдома? Може, у вас вже є приклади?`,
          text2: `<b>Навчальні матеріали:</b>
            
            Подивіться запис вебінару “Навчання українською: майстерня дитячого розвитку” про заняття з дошкільнятами та про те, як долати мовний бар'єр. Розповідає Марія Кирик, дитяча психологиня та авторка Youtube-каналу про психологію, розвиток і батьківство. До Києва вона переїхала із Горлівки: <a href="https://youtu.be/KMxCQlAYt_Y">https://youtu.be/KMxCQlAYt_Y</a>
            
            "Дитячий психолог" - сайт для батьків та дитячих психологів. Тут зібрані розвивальні ігри та заняття для дітей, поради психологів: <a href="https://dytpsyholog.com/">https://dytpsyholog.com/</a>
            
            Проєкт підтримки дитячого читання «BaraBooka. Простір української дитячої книги» – перший український універсальний інформаційний ресурс про видання для дітей та юнацтва: <a href="https://www.barabooka.com.ua/">http://www.barabooka.com.ua/</a>
`,
          taskTitle: `Завдання`,
          task: `Залучіть своїх учнів під час занять до перекладу слів з російської на українську мову. Також можна давати їм домашнє завдання - знайти цікаві українські слова, які можна було б використовувати на ваших заняттях.`,
      },
      {
          id: "18",
          pathUrl: "901244",
          name: `День 18. Натхнення`,
          subheader: ``,
          text: `З чого починається наше натхнення? А бажання викладати українською? Насамперед із розуміння нас самих та наших цінностей. Психологи радять дослухатися до самих себе, зрозуміти свій спосіб мислення і віднайти власне сприйняття світу. Вам допоможуть і підтримають, але чи йти далі – рішення лише ваше. Тільки від вас залежить, чи викладатимете ви українською й далі. Ви самі можете себе надихнути на звершення важливих справ.`,
          text2: `<b>Знайдіть час</b>
          Виділіть час, щоб подумати про своє життя. Чи задоволені ви тим, чим займаєтесь? А що хочете покращити? Чи змінилося щось із того часу, коли ви почали викладати українською? Можливо, такі запитання на перший погляд здаються банальними, але це своєрідний чек-ап, що у вас усе в порядку. Спробуйте знайти у цьому метушливому світі хоча б 10 хвилин на день для роздумів наодинці. 
          
          <b>Пишіть українською</b>
          Про те, чого ви хочете від життя, яким бачите свій ідеальний день і чим прагнете займатися. Записуйте цікаві слова, намагайтеся вживати їх у своєму мовленні, шукайте співрозмовників. Записуйте свої роздуми, запитання, тривоги та переживання, особливо коли вам необхідно прийняти важливе рішення – так ви краще проаналізуєте ситуацію та зробите все правильно й без поспіху.
          
          <b>Читайте українською</b>
          Книги можуть стати суперпаливом для розпалювання вашого натхнення. Після хорошої книги з’являються нові ідеї та мотивація, навіть погляди можуть кардинально змінитися. 
          
          <b>Створюйте середовище, що надихає</b>         
          Люди, проєкти, повідомлення, зустрічі, книги, ранкові ритуали – усе це має заряджати вас натхненням, мотивацією бути класним прикладом для своїх учнів, дарувати цікаві ідеї та хороший настрій. (за матеріалами сайту: <a href="https://happymonday.ua/5-porad-yak-ne-chekaty-na-nathnennya-a-stvoryuvaty-jogo">happymonday.ua</a>)
          
          Відеоролик для натхнення: <a href="https://youtu.be/lyQ-35IKF_k">https://youtu.be/lyQ-35IKF_k</a>
          `,
          taskTitle: `Завдання`,
          task: `Перегляньте ваші записи впродовж челенджу. Пам'ятаєте мотиваційний лист, який ви писали першого дня? Проаналізуйте, що змінилось за ці майже три тижні. Чи ці зміни відчутні й видимі? Що ще потрібно зробити, щоб досягти цілі? Напишіть нам у чаті, що вас зараз мотивує і надихає викладати українською.`,
      },
      {
          id: "19",
          pathUrl: "771244",
          name: `День 19. Читаємо українською`,
          subheader: ``,
          text: `Доброго ранку! 
                Читання українською мовою дуже помічне не лише для збільшення словникового запасу, а й просто для приємного проведення часу. Ми зібрали для вас ресурси, з яких можна дізнатись, що нового українською з’явилось в книгарнях і де шукати книжки українською.`,
          text2: `<b>Читомо</b> <a href="https://chytomo.com/">https://chytomo.com/</a>
          «Це медіа про книгу в усіх її проявах і про осмислене читання як трамплін, інструмент для самоосвіти та реалізації». Тут ви можете дізнатися про свіженькі книжки, видані українською мовою, про лауреатів Букерівської премії, про те, що читають зірки, а ще списки книжок для розвитку, про інформаційну безпеку та ін.
                   
          <b>Барабука</b> <a href="https://www.barabooka.com.ua/">https://www.barabooka.com.ua/</a>
          Тут чимало новин про те, що новенького з'явилось у дитячій літературі, розповіді про книжки для дітей, огляди дуже страшних книжок, поради, де купити дитячі книжки в Україні. А ще тут є «Шпаргалка для вчителя: літературне читання 1-4 клас» та проєкт #ЖивіПисьменники.
          
          <b>Читаємо книги разом з дітьми</b> <a href="https://www.facebook.com/groups/393191144554346">https://www.facebook.com/groups/393191144554346/</a>
          Група у Facebook, де учасники діляться новинками та враженнями про книжки для дітей.
          
          <b>ВРАЖЕННЯ UA</b>| Література українською, читання, книги та фільми, серіали <a href="https://www.facebook.com/groups/vrajenniya.ua ">https://www.facebook.com/groups/vrajenniya.ua </a>
          Група у Facebook, у якій учасники пишуть відгуки про прочитані книжки українською мовою.
          
          <b>ЛітАкцент</b> <a href="http://litakcent.com/">http://litakcent.com/</a>
          Як вони самі про себе кажуть, це видання, покликане бути провідником у світі сучасного письменства. Цікаве для тих, хто більш-менш систематично відвідує книгарні, хто має смак до гарної літератури і хоче зіставити свої оцінки новинок із судженнями літературознавців.
          
          <b>Книжковий блог Yakaboo</b> <a href="https://blog.yakaboo.ua/">https://blog.yakaboo.ua/</a>
          Тут ви знайдете поради, що почитати, дізнаєтесь про книжкові новинки, цікаві факти про сучасних письменників – українських і не тільки, усе про віммельбухи (книжки-картинки) і книжки з червоними обкладинками.
          
          <b>Блог Видавництва Старого Лева</b> <a href="https://starylev.com.ua/club">https://starylev.com.ua/club</a>
          Історія однієї обкладинки, розповіді про книжки, книжкові тести, уривки із книг, поради щодо зацікавлення дітей читанням – це все тут є.
          
          <b>Друг читача</b> <a href="https://vsiknygy.net.ua/">https://vsiknygy.net.ua/</a>
          Тут є відеоогляди книжок, розмови з авторами, тести на кшталт «Відгадай письменника за цитатою» та інше цікаве довколалітературне.
          
          <b>Блог Книгарні «Є»</b> <a href="https://book-ye.com.ua/blog/">https://book-ye.com.ua/blog/</a>
          Тут можна почитати про новинки книжкового світу, думки про літературу, про лауреатів Нобелівської премії тощо.
          `,
          taskTitle: `Завдання`,
          task: `Напишіть, які ваші улюблені книжки. Яку книжку плануєте прочитати українською після челенджу?`,
      },
      {
          id: "20",
          pathUrl: "961244",
          name: `День 20. Корисне з челенджу`,
          subheader: ``,
          text: `Доброго ранку, друзі! Завтра завершиться наш челендж "Навчай українською" - і ми вже сумуємо за вами. 7 вебінарів, 10 практикумів, сотні дописів у чатах - усе для того, щоб кожен із понад тисячі учасників зміг повправлятися в українській.
          
          Тут зібрали для вас корисну інформацію, яку давали впродовж челенджу
          `,
          text2: `<b>Вебінари: </b>
          <li>“Як почати навчати? Перші кроки до української мови викладання”. Наталя Вуйтік, редакторка й перекладачка, викладачка української мови <a href="https://youtu.be/JMAF_pSOBws">https://youtu.be/JMAF_pSOBws</a></li>
          <li>“Хореографія українською”. Ірина Бодрик, засновниця і керівниця студії танцю "Несамовиті" <a href="https://youtu.be/9C3CVLp-g9s">https://youtu.be/9C3CVLp-g9s</a></li>         
          <li>“Психологічна підтримка зміни мови”. Психотерапевтка, кандидатка психологічних наук, наукова співробітниця Інституту соціальної та політичної психології Ольга Кухарук <a href="https://youtu.be/YbYaMylxM8c">https://youtu.be/YbYaMylxM8c</a></li>         
          <li>“Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка”. Письменниця, редакторка, тренерка з ейдетики Мар’яна Лелик <a href="https://youtu.be/o_2rAf5M1w0">https://youtu.be/o_2rAf5M1w0</a></li>         
          <li>"До Олімпу через українське слово". Викладачі Львівського державного університету фізичної культури імені Івана Боберського Богдан Виноградський – доктор наук, професор, майстер спорту та Оксана Борис – керівниця редакційно-видавничого відділу, колишня лекторка української мови Університету Етвеша Лоранда (Будапешт, Угорщина) <a href="https://youtu.be/5-w8dD-JXgk">https://youtu.be/5-w8dD-JXgk</a></li>         
          <li>“Голос і мовлення”. Олександр Жуган - викладач акторської та сценічної майстерності, психолог <a href="https://youtu.be/-QRBoDNdRp8 ">https://youtu.be/-QRBoDNdRp8 </a></li>         
          <li>«Як перейти на українську всім колективом?» команда Дитячо-юнацького центру “Штурм” (м. Дніпро) - найбільшого закладу позашкільної освіти в Україні, переможця Всеукраїнського та міжнародного проєкту “Дистанційна робота. Заклади позашкілля”, переможця проєкту “Найкращі практики молодіжної роботи” <a href="https://youtu.be/RLrmhYv-GSA">https://youtu.be/RLrmhYv-GSA</a></li>         
          <b>Записи вебінарів минулих челенджів:</b>
          <li>Як перейти на українську мову викладання. Викладач акторської майстерності Олександр Жуган <a href="https://youtu.be/rIb2skc1tv4">https://youtu.be/rIb2skc1tv4</a></li>         
          <li>Навчати українською: виклики і можливості. Мовна тренерка Ольга Андрусенко <a href="https://youtu.be/ZVlEVOdP6YI">https://youtu.be/ZVlEVOdP6YI</a></li>         
          <li>Навчання українською: майстерня дитячого розвитку. Дитяча психологиня Марія Кирик <a href="https://youtu.be/KMxCQlAYt_Y">https://youtu.be/KMxCQlAYt_Y</a></li>         
          <li>Мова та виклики для спортивного тренера. Найкращий каратист України Станіслав Горуна <a href="https://youtu.be/3IuIL8td6rQ">https://youtu.be/3IuIL8td6rQ</a></li>         
          <li>Процес переходу на іншу мову: поради і таємниці. Вчителька англійської Олена Жупанова <a href="https://youtu.be/FXACB6bukmg">https://youtu.be/FXACB6bukmg</a></li>         
          <li>Психологічна підтримка зміни мови. Психологиня Ольга Кухарук <a href="https://youtu.be/lcwo0938Bd8">https://youtu.be/lcwo0938Bd8</a></li>         
          <li>Українська. Історії зірок <a href="https://bit.ly/2QZFQKz">https://bit.ly/2QZFQKz</a></li>         
          <li>Навчати українською: виклики і можливості. Мовна тренерка Ольга Андрусенко <a href="https://youtu.be/tHh6cQzzMBU">https://youtu.be/tHh6cQzzMBU</a></li>         
          <li>Запам'ятовувати легко: українська мова + ейдетика та мнемотехніка. Письменниця, тренерка з ейдетики та мнемотехніки Мар'яна Лелик <a href="https://youtu.be/nI4zCGD_cwU">https://youtu.be/nI4zCGD_cwU</a></li>         
          <li>Голос і мовлення. Викладач акторської майстерності Олександр Жуган <a href="https://youtu.be/iT_BfFkoHSk">https://youtu.be/iT_BfFkoHSk</a></li>         
          <li>Онлайн-зустріч. Навчати українською: досвід дитячо-юнацького центру "Штурм" з Дніпра <a href="https://youtu.be/ykRab96hK9A">https://youtu.be/ykRab96hK9A</a></li>         
          
          <b>Мовні курси</b>
         
          Освітня платформа “Є-мова” <a href="https://emova.language-ua.online/">https://emova.language-ua.online/</a>
          <a href="https://www.youtube.com/c/eMova/playlists">https://www.youtube.com/c/eMova/playlists</a>
          
          
          <b>Матеріали:</b>
          
          Як перейти на українську мову викладання для дітей? Досвід керівника студії комп’ютерної графіки <a href="https://bit.ly/3mdLHXz">https://bit.ly/3mdLHXz</a>
         
          4 кроки до викладання українською. Досвід тренерки <a href="https://bit.ly/2HcBMCr">https://bit.ly/2HcBMCr</a>
        
          Український Youtube <a href="https://bit.ly/3tTRMfB">https://bit.ly/3tTRMfB</a>
        
          Youtube-канали для дітей <a href="https://bit.ly/3sQRqVR">https://bit.ly/3sQRqVR</a>
       
          Ігри для дітей та підлітків <a href="https://bit.ly/3sRDAT3">https://bit.ly/3sRDAT3</a>

`,
          taskTitle: `Завдання`,
          task: `Упродовж наступного тижня знайдіть декілька годин вашого часу, щоб переглянути ті вебінари, які пропустили.`,
      },
      {
          id: "21",
          pathUrl: "991244",
          name: `День 21. Відчуйте себе переможцями`,
          subheader: ``,
          text: `Вітаємо! Друзі, ви пройшли ці три тижні челенджу “Навчай українською”! Ви це змогли! 
          Ми радіємо разом із вами! Сподіваємось, що ви отримали за ці три тижні цінне - досвід, практику й однодумців.
          `,
          text2: ``,
          taskTitle: `Завдання`,
          task: `Напишіть собі лист: "Що буде після челенджу". Спробуйте чітко уявити ваші кроки до цілі - викладання українською мовою. Проговоріть ці кроки з вашими вихованцями - досягайте мети разом! Зичимо успіхів!
          <br></br>         
          Команда челенджу “Навчай українською” завжди з вами!
          `,
      },
  ],
};
